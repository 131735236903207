/* eslint-disable no-lone-blocks */
/* eslint-disable jsx-a11y/anchor-is-valid */
import DatePicker from 'react-date-picker';
import TimePicker from 'rc-time-picker';
import moment from 'moment';
import 'rc-time-picker/assets/index.css';
import * as formHtml from "./formHtml";
import videoIcon from "../../assets/img/videoIcon.svg";
import imageIcon from "../../assets/img/imageIcon.svg";
import carouselIcon from "../../assets/img/carouselIcon.svg";
import crossIcon from "../../assets/img/cancelImg.svg";
import plusIcon from "../../assets/img/plusIcon.svg";
import SignatureCanvas from 'react-signature-canvas'
import {useEffect, useRef, useState} from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import {Carousel} from 'react-responsive-carousel';
import {Editor} from "@tinymce/tinymce-react";
import {fetchImage} from "../functions";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import {deepClone, getConditionalStringResponse} from "../functions";
import * as _ from "lodash";
import {evaluateExpression} from "../functions/string-conversion";


function fetchS3Link(key) {
    let NewSrc = fetchImage(key)
    return NewSrc.then((res) => {
        return res
    })
}
export const TextInput = ({ data, onChangeFields }) => {
    let Tag = data.type !== "textarea" ? "input" : "textarea";
    return (
        <div key={data.id} >
            <label>{data.label ? data.label : "Untitled"}<small>{data.required ? '' : ' Optional'}</small></label>
            <Tag
                type={data.type}
                className="form-control"
                minLength={data.setInputValueRange && data.min_value ? data.min_value : null}
                maxLength={data.setInputValueRange && data.max_value ? data.max_value : null}
                placeholder={data.placeholder ? data.placeholder : `Input Type ${data.type}`}
                onChange={(e) => onChangeFields(e.target.value)}
                pattern={data.pattern}
                required={data.required}
                defaultValue={data.value}
                id={data.id}
            />
        </div>
    )
}

export const CheckBoxes = ({ data, onChangeFields, formData }) => {
    return (
        <div key={data.id} >
            <label>{data.label ? data.label : "Untitled"}<small>{data.required ? '' : ' Optional'}</small></label>
            {data.options.map(opt => {
                return <div key={opt.key}>
                    <input
                        id={data.id}
                        className="form-check-input"
                        data={data.typeRadio}
                        type={data.typeRadio ? "radio" : "checkbox"}
                        name={data.label ? data.label : "Untitled"}
                        value={opt.value}
                        onChange={(e) => {
                            if (data.typeRadio) {
                                onChangeFields(opt.value)
                            } else {
                                data.options = _.map(data.options, v => {
                                    if (v.id === opt.id) {
                                        v.checked = e.target.checked
                                    }
                                    return v;
                                });

                                const val = [];
                                data.options.map(v => {
                                    if (v.checked) {
                                        val.push(v.value)
                                    }
                                })
                                onChangeFields(val)
                            }
                        }}
                    />&nbsp;
                    <label value={data.hasValues ? opt.value : opt.key}>{opt.key}</label>
                </div>
            })}
        </div>
    )
}
export const DropDown = ({ data, onChangeFields, formData }) => {
    const [form, setForm] = useState(data.options[data.defaultValue].value)
    useEffect(() => {
        onChangeFields(form)
    }, [form])
    return (
        <div key={data.id} >
            <label>{data.label ? data.label : "Untitled"}<small>{data.required ? '' : ' Optional'}</small></label>
            <select id={data.id} className="form-control" value={form} onChange={(e) => { setForm(e.target.value) }}>
                <option value='' hidden>Select</option>
                {data.options.map((opt, index) => {
                    return (
                        <option key={opt.value} value={opt.value} >{opt.value}</option>
                    );
                })}
            </select>
        </div>
    )
}
export const SelectDatePicker = ({ data, onChangeFields, formData }) => {
    let [date, setDate] = useState(data.value == "" ? new Date() : data.value)
    useEffect(() => {
        if (date !== data.value) {
            onChangeFields(date)
        }
    }, [date])
    return (
        <div key={data.id} >
            <label>{data.label ? data.label : "Untitled"}<small>{data.required ? '' : ' Optional'}</small></label><br />
            <DatePicker id={data.id} onChange={(e) => { console.log(e); setDate(e) }} value={date} clearIcon={null} />
        </div >
    )
}
export const FileUpload = ({ data, onChangeFields, formData }) => {
    return (
        <div key={data.id} >
            <label>{data.label ? data.label : "Untitled"}<small>{data.required ? '' : ' Optional'}</small></label><br />
            <input id={data.id} type="file" onChange={e => onChangeFields(e.target.files)} />
        </div>
    )
}
export const Paragraph = ({ data }) => {
    const [content, setContent] = useState("")
    useEffect(() => {
        setContent(data.label)
    }, [data.label])
    const [sHowEditButton, setSHowEditButton] = useState(false)
    useEffect(() => {
        setSHowEditButton(data.userContentEditable)
    }, [data.userContentEditable])
    const editorRef = useRef(null);
    const [showEditor, setShowEditor] = useState(false);
    const [editorContent, setEditorContent] = useState("")

    useEffect(() => {
        if (showEditor) {
            setEditorContent(content)
        } else {
            if (editorContent.length > 0 && data.content !== editorContent) {
                setContent(editorContent)
            }
        }
    }, [showEditor])

    return (
        <div key={data.id} >
            {sHowEditButton && <button className={'editPreviewForm'} onClick={(e) => { data.userContentEditable && setShowEditor(!showEditor) }} ><i className="fa-regular fa-pen-to-square"></i></button>}
            {!showEditor ? <p style={{
                fontWeight: data.bold ? "bold" : "",
                fontStyle: data.italic ? "italic" : ""
            }}
                dangerouslySetInnerHTML={{
                    __html: data.label ? JSON.parse(data.label) : "Content Here",
                }}></p> :
                <>
                    <Editor
                        id={data.id}
                        required
                        apiKey={"f44d3cc0381fc1bd2133f6c8530fabc8da6dcef684bb7eaf723177533939a4e1"}
                        onInit={(evt, editor) =>
                            (editorRef.current = editor)
                        }
                        value={editorContent ? JSON.parse(editorContent) : ""}
                        init={{
                            selector: "textarea",
                            height: 200,
                            menu: {
                                custom: {
                                    title: "custom",
                                    items: "basicitem nesteditem toggleitem",
                                },
                            },
                            toolbar:
                                "bold italic strikethrough link bullist numlist underline | ",
                            font_formats:
                                "Arial=arial,helvetica,sans-serif; Courier New=courier new,courier,monospace; AkrutiKndPadmini=Akpdmi-n",
                            block_formats:
                                "Paragraph=p; Header 1=h1; Header 2=h2; Header 3=h3",
                            plugins: [
                                "image",
                                "help",
                                "link",
                                "codesample",
                                "lists",
                            ],
                            branding: false,
                            content_style:
                                "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",

                            menubar: false,
                        }}
                        onEditorChange={(e) => {
                            setEditorContent(JSON.stringify(e))
                        }}
                    />
                </>}
        </div>
    )
}
export const PageBreak = ({ data }) => {
    return (
        <div key={data.id} id={data.id}>
            <div className="pagebreak">
                {data.buttons.map((button, index) => {
                    if (button.hidden) {
                        return (null);
                    }
                    return (
                        <button key={`choice__${index}`} type="button" className="">{button.label}</button>
                    )
                })}
                <div className='pagesplit-top' >
                </div>
                <div className='pagesplit-bottom'>
                </div>
            </div>

        </div>
    )
}
export const Section = (props) => {
    const [form, setForm] = useState(props.formData);
    useEffect(() => {
        props.onChangeFields(props.data.label ? props.data.label : props.data.id, form)
    }, [form])
    function onChangeFields(data) {
        props.onChangeFields("dummy")
    }
    return (
        <div key={props.data.id} id={props.data.id}>
            <h4 className="sectionTitle">{props.data.label ? props.data.label : "Title Name"} :</h4>
            <div className="widget-dropsecton" >
                {props.data.childComponent.map((row, index) => {
                    return (
                        <div className="row" key={`choice__${index}`}>
                            {row.childComponent.map((item, refVal) => {
                                if (item.conditionalOperation.rules.rules.length > 0) {
                                    if (!getConditionalStringResponse(item.conditionalOperation.rules)) {
                                        return
                                    }
                                }
                                let Tag = formHtml[item.element]
                                return (
                                    <div
                                        className={`col-lg-${item.width ? item.width : 6}`}
                                        key={item.id}
                                    >
                                        <div className="form-group" >

                                            <Tag data={item} onChangeFields={onChangeFields} />
                                        </div>
                                    </div>
                                )
                            })
                            }
                        </div >
                    )
                })}

            </div>
        </div>
    )
}

export const RepeatingSection = (props) => {
    const [form, setForm] = useState([props.formData]);
    useEffect(() => {
        props.onChangeFields(props.data.label ? props.data.label : props.data.id, form)
    }, [form])
    function onChangeFields(data) {
        // props.onChangeFields("dummy")
    }
    let DataCopy = {
        index: 0,
        components: deepClone(props.data.childComponent)
    }
    const [htmlStaructure, setHtmlStaructure] = useState([DataCopy])
    return (
        <div key={props.data.id} id={props.data.id}>
            <h4 className="sectionTitle">{props.data.label ? props.data.label : "Title Name"} :</h4>
            <div className="widget-dropsecton" >
                {htmlStaructure.map((html, index) => {
                    return <div key={`choice10__${index}`}>
                        <h5 className="subtitle"><img src={crossIcon} onClick={() => { htmlStaructure.splice(index, 1); setHtmlStaructure([...htmlStaructure]) }} />{props.data.itemLabel ? props.data.itemLabel : "Item"} {html.index + 1}</h5>

                        {
                            html.components.map((row, index2) => {
                                return (
                                    <div className="row" key={`choice__${index2}`}>
                                        {row.childComponent.map((item, refVal) => {
                                            if (item.conditionalOperation.rules.rules.length > 0) {
                                                if (!getConditionalStringResponse(item.conditionalOperation.rules)) {
                                                    return
                                                }
                                            }
                                            let Tag = formHtml[item.element]
                                            return (
                                                <div
                                                    className={`col-lg-${item.width ? item.width : 6}`}
                                                    key={item.id}
                                                >
                                                    <div className="form-group" >

                                                        <Tag data={item} onChangeFields={onChangeFields} />
                                                    </div>
                                                </div>
                                            )
                                        })
                                        }
                                    </div >
                                )
                            })
                        }
                    </div>

                })}
                <button className="btn-AddItem" onClick={() => {
                    DataCopy.index += 1
                    htmlStaructure.push(deepClone(DataCopy));
                    setHtmlStaructure([...htmlStaructure])
                }}><img src={plusIcon} /> {props.data.addButtonText ? props.data.addButtonText : "Add Item"}</button>
            </div>
        </div>
    )
}


export const Image = ({ data }) => {
    let [imagSrc, setImageSrc] = useState("")
    useEffect(() => {
        fetchS3Link(data.src).then((res) => {
            setImageSrc(res)
        })
    }, [data.src])
    return (
        <>
            {data.src ?
                <img src={imagSrc} alt="Image" className="DynamicImg"></img>
                : <span className="defaultIcon"><img src={imageIcon} /></span>
            }
        </>
    )
}
export const Video = ({ data }) => {
    let [Src, setSrc] = useState("")
    useEffect(() => {
        fetchS3Link(data.src).then((res) => {
            setSrc(res)
        })
    }, [data.src])
    return (
        <>
            {data.src && Src !== "" ?
                <>
                    <video width="100%" height={(data.width * 315) / 6} controls autoplay>
                        <source src={Src} type="video/mp4" />
                        <source src={Src} type="video/ogg" />
                        Your browser does not support the video tag.
                    </video>
                </>
                : <span className="defaultIcon"><img src={videoIcon} /></span>
            }
        </>
    )
}
export const SelectTimePicker = ({ data, onChangeFields, formData }) => {
    const [form, setForm] = useState(data.value ? data.value : new moment());
    useEffect(() => {
        onChangeFields(form)
    }, [form])
    return (
        <div key={data.id}>
            <label>{data.label ? data.label : "Untitled"}<small>{data.required ? '' : ' Optional'}</small></label><br />
            <TimePicker id={data.id} onChange={(e) => { setForm(e) }} showSecond={false} use12Hours value={form} />
        </div>
    )
}
export const RatingScale = ({ data, onChangeFields, formData }) => {
    const [form, setForm] = useState(formData);
    useEffect(() => {
        onChangeFields(form)
    }, [form])
    let ratingType = data.typesOfRating.find((type) => type.type == data.selectedRatingType)
    return (
        <div key={data.id} id={data.id} >
            <label>{data.label ? data.label : "Untitled"}<small>{data.required ? '' : ' Optional'}</small></label><br />
            <div className="questionSection">
                <table className="table">
                    <thead>
                        <tr>
                            <td></td>
                            {ratingType.structure.map((row, index) => {
                                return <td className="text-center" key={`choice__${index}`}>{row}</td>
                            })}
                        </tr>
                    </thead>

                    <tbody>
                        {data.fields.map((row, index) => {
                            return (<tr key={`choice__${index}`}>
                                <td>{row.label}</td>
                                {ratingType.structure.map((radio, radioIndex) => {
                                    return (
                                        <td className="text-center" key={`choice__${radioIndex}`}>
                                            <input type="radio" name={index} defaultChecked={radioIndex == data.defaultRadioValue} onChange={(e) => { onChangeFields(index, radioIndex) }} />
                                        </td>
                                    )
                                })}
                            </tr>)
                        })}
                    </tbody>
                </table>
            </div>
        </div>
    )
}
export const Signature = ({ data, onChangeFields, formData }) => {
    const [form, setForm] = useState(formData);
    useEffect(() => {
        onChangeFields(form)
    }, [form])
    const [signatureType, setSignatureType] = useState("Draw");
    const [imageURL, setImageURL] = useState(null);
    const sigCanvas = useRef()
    function create() {
        const URL = sigCanvas.current.getTrimmedCanvas().toDataURL("image/png");
        setImageURL(URL);
    };

    const convertToBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file);
            fileReader.onload = () => {
                resolve(fileReader.result);
            };
            fileReader.onerror = (error) => {
                reject(error);
            };
        });
    };
    return (<form>
        <label
            className='signatureHeading'>{data.label ? data.label : "Untitled"}<small>{data.required ? '' : ' Optional'}</small></label>
        <div className="signatureSection" key={data.id} id={data.id}>
            {
                signatureType == "Draw" ?
                    <>
                        <SignatureCanvas penColor='black'
                                         canvasProps={{
                                             width: 115 * data.width,
                                             height: "auto",
                                             className: 'sigCanvas',
                                             border: "1px solid black"
                                         }} ref={sigCanvas} onEnd={async () => {
                            let url = await convertToBase64(sigCanvas.current.getTrimmedCanvas().toDataURL("image/png"));
                            onChangeFields(url)
                        }}/>
                    </>
                    :
                    <input className="signatureText" type="text"/>
            }
        </div>
        <div className="row mt-3">
            <div className="form-group">
                <label>Name</label>
                <input type="text" className="form-control" onChange={(e) => {
                    form["Name"] = e.target.value;
                    setForm(form)
                }} id={data.fields["Name"].id} name="Name" defaultValue={form && form["Name"] ? form["Name"] : ""}/>
            </div>
        </div>
            <div className="signatureBtns">
                <button onClick={() => sigCanvas.current.clear()}>Clear</button>
                <button onClick={() => setSignatureType("Draw")}>Draw</button>
                <button onClick={() => setSignatureType("Text")}>Text</button>

                <button className="create" style={{display: "none"}} onClick={() => create()}>
                    Create
                </button>
            </div>
    </form>
)
}

function CrouselImage({row}) {
    let [imagSrc, setImageSrc] = useState("")
    useEffect(() => {
        fetchS3Link(row.src).then((res) => {
            setImageSrc(res)
        })
    }, [])
    return <img key={row.id} src={imagSrc}/>
}

export const ImageSlider = ({data}) => {
    data.images.map((row) => {
    })

    return (
        data.images[0].src ?
            <Carousel interval="5" verticalSwipe="standard" >
                {
                    data.images.map((row, index) => {

                        return (
                            <div key={`choice__${index}`}>
                                <CrouselImage row={row} />
                                <p className="legend">{row.altName}</p>
                            </div>
                        )
                    })
                }

            </Carousel> : <span className="defaultIcon carouselIcon"><img src={carouselIcon} /></span>
    )
}

export const ContactInformation = ({ data, onChangeFields, formData }) => {
    const [form, setForm] = useState({
        "Name": "",
        "ContactPh": "",
        "Address": "",
        "City": "",
        "State": "",
        "Zip": "",
        "ContactEmail": ""
    });
    useEffect(() => {
        if (formData !== undefined && JSON.stringify(formData) !== JSON.stringify(form)) {
            // setForm(formData)
        }
    }, [formData])
    useEffect(() => {
        onChangeFields(form)
    }, [form])
    return (
        <form >
            <label className='contactInfoHeading'>Contact Information:<small>{data.required ? '' : ' Optional'}</small></label>
            <div className="contactInfo">
                <div className="row">
                    <div className="col-lg-6">
                        <div className="form-group">
                            <label>MI./ Name</label>
                            <input type="text" className="form-control" onChange={(e) => { form["Name"] = e.target.value; setForm(form) }} id={data.fields["Name"].id} name="Name" defaultValue={form && form["Name"] ? form["Name"] : ""} />
                        </div>
                    </div>

                    <div className="col-lg-6">
                        <div className="form-group">
                            <label>Contact Ph:</label>
                            <PhoneInput country={'us'} type="number" className="" onChange={(e) => { form["ContactPh"] = e; setForm(form) }} id={data.fields["ContactPh"].id} name="ContactPh" defaultValue={form && form["ContactPh"] ? form["ContactPh"] : ""} />
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="form-group">
                            <label>Address</label>
                            <input type="text" className="form-control" onChange={(e) => { form["Address"] = e.target.value; setForm(form) }} id={data.fields["Address"].id} name="Address" defaultValue={form && form["Address"] ? form["Address"] : ""} />
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="form-group">
                            <label>City</label>
                            <input type="text" className="form-control" onChange={(e) => { form["City"] = e.target.value; setForm(form) }} id={data.fields["City"].id} name="City" defaultValue={form && form["City"] ? form["City"] : ""} />
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="form-group">
                            <div className="row">
                                <div className="col-lg-6">
                                    <label>State</label>
                                    <input type="text" className="form-control" onChange={(e) => { form["State"] = e.target.value; setForm(form) }} id={data.fields["State"].id} name="State" defaultValue={form && form["State"] ? form["State"] : ""} />
                                </div>

                                <div className="col-lg-6">
                                    <label>Zip</label>
                                    <input type="text" className="form-control" size="10" maxLength="10" onChange={(e) => {
                                        let val = e.target.value;
                                        var regExp = /[a-zA-Z]/g;
                                        if (!regExp.test(val)) {
                                            if (val.includes('-'))
                                                val = val.replaceAll('-', '');
                                            if (val.length > 5)
                                                val = val.substr(0, 5) + '-' + val.substr(5, val.length - 5);
                                            e.target.value = val;
                                            form["Zip"] = val; setForm(form)
                                        } else {
                                            e.target.value = form["Zip"]
                                        }
                                    }} id={data.fields["Zip"].id} name="Zip" defaultValue={form && form["Zip"] ? form["Zip"] : ""} />
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="form-group">
                            <label>Contact Email</label>
                            <input type="email" className="form-control" onChange={(e) => { form["ContactEmail"] = e.target.value; setForm(form) }} id={data.fields["ContactEmail"].id} name="ContactEmail" defaultValue={form && form["ContactEmail"] ? form["ContactEmail"] : ""} onBlur={(e) => {
                                if (/\S+@\S+\.\S+/.test(e.target.value)) {
                                    e.target.nextSibling.classList.add("hidden")
                                } else {
                                    console.log(e.target.nextSibling.classList)
                                    e.target.nextSibling.classList.remove("hidden")
                                }
                            }} />
                            {/* <small className='hidden'>please enter a valid email address</small> */}
                            <div className="alert alert-danger hidden" role="alert">
                                <small>Please enter a valid email address</small>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    )
}
export const Content = ({ data, form }) => {
    useEffect(() => {
        if (document.getElementById('formBuilderView')) {
            let allCodeKeys = document.getElementById('formBuilderView').getElementsByTagName('code');
            if (allCodeKeys.length > 0) {
                [...new Array(allCodeKeys.length)].map((key, index) => {
                    let data = _.get(form, allCodeKeys[index].outerText.split("."))
                    if (data.length > 0) {
                        allCodeKeys[index].outerText = data
                    }
                })
            }
        }

    }, [form])
    return (
        <div key={data.id} id={data.id}>
            <p style={{
                fontWeight: data.bold ? "bold" : "",
                fontStyle: data.italic ? "italic" : ""
            }}
                dangerouslySetInnerHTML={{
                    __html: data.DynamicContent ? JSON.parse(data.DynamicContent) : "Dynamic Content Here",
                }}></p>
        </div>
    )
}
export const Calculation = ({data, form}) => {
    return <div className="row">
        <div className="col-12">
            <p className="mb-0"><b>{data?.label}</b></p>
            <p className="mb-0">{evaluateExpression(data, form)}</p>
            <p><i>{data.helptext}</i></p>
        </div>
    </div>
}