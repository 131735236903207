import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import './App.css';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import ReactFormBuilder from './components/ReactFormBuilder';
import FormsList from './screens/FormList';
import BuildForm from './screens/BuildForm'
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


function App() {
  return (
    <div className="App">
      <ToastContainer
        position="top-center"
        hideProgressBar={true}
        newestOnTop={false}
        autoClose={5000}
        bodyClassName="toastBody"
      />
      <Router>
        <Routes>
          <Route path="/" element={<FormsList />} />
          <Route path="/form-builder/:form/:id" element={<ReactFormBuilder />} />
          <Route path="/form-builder" element={<ReactFormBuilder />} />
          <Route path="/:id" element={<BuildForm />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
