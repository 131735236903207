import * as _ from "lodash";

export const removeIfExists = (array, item) => {
  const index = array.indexOf(item);
  if (index !== -1) {
    array.splice(index, 1);
  }
  return array;
}
export const addUniqueItem = (array, newItem) => {
  if (!array.includes(newItem)) {
    array.push(newItem);
  }
}
export const removeItemByIndex = (array, index) => {
  array.splice(index, 1);
}
export const removeItemByValue = (array, value) => {
  return array.filter(item => item !== value);
}
export const removeLastItem = (array) => {
  array.pop();
}
export const createSuggestionList = (selectedWidgets) => {
  const tempSelectedList = [];
  _.forEach(selectedWidgets, child => {
    _.forEach(child.childComponent, childCompo => {

      if ((childCompo.element === "TextInput" && childCompo.type === "number")
        || childCompo.element === "CheckBoxes"
      ) {
        tempSelectedList.push({
          label: childCompo.label,
          id: childCompo.id
        });
      }
    })
  })

  return makeLabelsUnique(tempSelectedList);
}
export const  makeLabelsUnique = (arrayOfObjects) => {
  const labelCount = {};
  arrayOfObjects.forEach((obj) => {
    if (labelCount[obj.label] === undefined) {
      labelCount[obj.label] = 1;
    } else {
      labelCount[obj.label]++;
      obj.label = `${obj.label}${labelCount[obj.label]}`;
    }
  });

  return arrayOfObjects;
}