/* eslint-disable jsx-a11y/anchor-is-valid */
import editIcon from "../../assets/img/edit.svg";
import deleteIcon from "../../assets/img/bin.svg";
import "./style.css";
import { useEffect, useState } from "react";
import { apiGetMethod, apiDeleteMethod } from "../../api/rest";
import { allApiUrl } from "../../api/apiRoute"
import Loader from "../Loader";
import Modal from 'react-modal';
import crossIcon from '../../assets/img/iconCross.svg';


function SubFormListing() {
    const [subformList, setSubFormList] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [openDeleteModal, setOpenDeleteModal] = useState(false)
    const [selectedForm, setSelectedForm] = useState({ id: null, title: "" })
    useEffect(() => {
        fetchSubFormList();
    }, [])
    function fetchSubFormList() {
        setIsLoading(true)
        apiGetMethod(allApiUrl.SubForm).then((response) => {
            setIsLoading(false)
            setSubFormList(response.data.data)
        })
    }
    function removeForm(id) {
        apiDeleteMethod(allApiUrl.SubForm + "/" + id).then((response) => {
            fetchSubFormList();
        })
    }
    function DeleteModal(id) {

        const customStyles = {
            content: {
                top: '50%',
                left: '50%',
                right: '100%',
                bottom: 'auto',
                marginRight: '-30%',
                transform: 'translate(-50%, -50%)',
            },
        };
        function CloseModal() {
            setOpenDeleteModal(false)
        }
        return (

            <div>
                <Modal
                    isOpen={openDeleteModal}
                    onRequestClose={() => CloseModal()}
                    style={customStyles}
                    contentLabel="Delete Modal"
                >

                    <div className="modalHeader">
                        <h4>Delete Form</h4>
                        <button className="btn btn-closeModal" onClick={() => { setOpenDeleteModal(false) }}>
                            <img src={crossIcon} />
                        </button>
                    </div>
                    <div className="saveModalBody">
                        <p>Are you sure you want to delete <b>"{selectedForm.title}"</b>?</p>
                    </div>
                    <div className="btnSection text-end p-3">
                        <button className="btn btn-save" type="button" onClick={() => { CloseModal() }} >Cancel</button>
                        <button className="btn btn-save" onClick={() => { removeForm(selectedForm.id); CloseModal() }}>Delete</button>
                    </div>
                </Modal>
            </div >
        )
    }

    return (
        <>
            {subformList.length ?
                <>
                    {subformList.map((row, idx) => {
                        return <tr key={`row_${idx}`}>
                            <td>{row.formSettings.title.label}</td>
                            <td>{row.created_date}</td>
                            <td>
                                <a className="actionBtn" role="button" href={`form-builder/subform/${row._id}`} >
                                    <img src={editIcon} />
                                </a>
                                <a className="actionBtn" role="button" onClick={() => { setSelectedForm({ id: row._id, title: row.title }); setOpenDeleteModal(true) }}>
                                    <img src={deleteIcon} />
                                </a>
                            </td>
                        </tr>
                    })}
                </>
                :
                <tr>
                    <td></td>
                    <td>
                        {isLoading ? <Loader /> : <div className="text-center">No Records</div>}
                    </td>
                    <td></td>
                </tr>
            }
            {openDeleteModal && <DeleteModal />}

        </>
    );
}

export default SubFormListing;