import React, {useEffect, useState} from 'react';
import * as _ from "lodash";
import {createSuggestionList} from "../../shared/functions/array-conversion";
import {ClickAwayListener} from "@mui/material";

const TextAreaWithSuggestions = ({selectedWidgets, inputValProp, editElementProp, isTextAreaInput = true}) => {
  const tempSelectedList = createSuggestionList(selectedWidgets);

  const [inputValue, setInputValue] = useState(inputValProp);
  const [showSuggestions, setShowSuggestions] = useState(false);

  useEffect(() => {
    setInputValue(inputValProp)
  }, [inputValProp]);
  const handleChange = (value) => {
    setInputValue(value);
    handleOnSetTextArea(value);
  };

  const handleOnSetTextArea = (val) => {
    editElementProp(val, ["calculation"])
  }

  return (
    <ClickAwayListener onClickAway={() => {
      setShowSuggestions(false)
    }}>
      <div style={{position: 'relative', height: "calc(100% - 100px) !important"}}
         onFocus={(e) => {
           setShowSuggestions(true)
         }}
      >
        {isTextAreaInput ? <textarea
          className="form-control"
          id="suggestionTextArea"
          placeholder="="
          style={{minHeight: "calc(100% - 100px) !important"}}
          value={inputValue}
          onChange={(e) => handleChange(e.target.value)}
        /> : <input
          className="form-control pe-5"
          id="suggestionTextArea"
          placeholder="="
          value={inputValue}
          onChange={(e) => handleChange(e.target.value)}
        />}
        {showSuggestions && (inputValue.startsWith("=")) && (
          <div className="suggestion-list">
            <ul className="custom-select">
              {tempSelectedList.map((option, index) => (
                <li
                  key={index}
                  onClick={() => {
                    handleChange(inputValue + `$${option.label}__${option.id}`)
                    try {
                      const el = document.getElementById("suggestionTextArea");
                      el.focus();
                    } catch (e) {
                      console.log('focusError', e)
                    }
                  }}
                  className="option"
                >
                  {option.label}
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </ClickAwayListener>
  );
};

export default TextAreaWithSuggestions;
