/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState, useRef } from "react";
import { deepClone, uniqueID } from "../../shared/functions";
import { Editor } from "@tinymce/tinymce-react";
import AWS from "aws-sdk";
import ConditionalQueryBuilder from "../QueryBuilder";
import uploadIcon from "../../assets/img/uploadIcon.svg";
import deleteIcon from "../../assets/img/bin.svg";
import arrowRight from "../../assets/img/arrowRight.svg";
import crossIcon from "../../assets/img/crossIcon.svg";
import ImageEditor from "../ImageEditor";
import Loader from "../Loader";
import { formatQuery } from "react-querybuilder";
import {
  AddButtonJson,
  addStatusJson,
} from "../../shared/constants/widgetJson";
import * as _ from "lodash";
import CustomDialog from "../CustomDialog";
import { Image } from "../../shared/constants/widgetsHtml";
import {createSuggestionList} from "../../shared/functions/array-conversion";
import TextAreaWithSuggestions from "../shared/TextareaWithSuggestions";

AWS.config.update({
  accessKeyId: process.env.REACT_APP_AWS_S3_BUCKET_SECRET_ACCESS_KEY,
  secretAccessKey: process.env.REACT_APP_AWS_S3_BUCKET_ACCESS_KEY_ID,
  region: process.env.REACT_APP_AWS_S3_BUCKET_REGION,
  signatureVersion: "v4",
});

function EditFieldsModal({
  modalStatus,
  setModalStatus,
  data = {},
  updateSelectedField,
  selectedWidgets,
  setSelectedWidget,
}) {
  const [editForm, setEditForm] = useState({});
  const [ModalOpen, setModalOpen] = useState(false);
  const [calculationModalOpen, setCalculationModalOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState({});
  const [selectedFileIndex, setSelectedFileIndex] = useState(0);
  const [openImageEditor, setOpenImageEditor] = useState(false);
  const [allInputVariables, setAllInputVariables] = useState([]);
  const [editSubmitbtnSettings, setEditSubmitbtnSettings] = useState(false);
  const [editSubmitbtnSettingsIndex, setEditSubmitbtnSettingsIndex] =
    useState(null);
  const [editorKey, setEditorKey] = useState(_.random(1, 10000));

  useEffect(() => {
    if (Object.keys(editForm).length == 0) {
      setEditForm(deepClone(data));
    } else if (data.id !== editForm.id) {
      setEditForm(deepClone(data));
      setEditorKey(_.random(1, 10000));
    }
  }, [data]);
  const editorRef = useRef(null);
  const hiddenFileInput = React.useRef(null);
  const s3 = new AWS.S3();

  function closeModal() {
    setModalStatus(false);
    setSelectedWidget({});
  }
  let this_checked = editForm.hasOwnProperty("required")
    ? editForm.required
    : false;
  let this_read_only = editForm.hasOwnProperty("readOnly")
    ? editForm.readOnly
    : false;
  let this_checked_bold = editForm.hasOwnProperty("bold")
    ? editForm.bold
    : false;
  let this_checked_italic = editForm.hasOwnProperty("italic")
    ? editForm.italic
    : false;
  let this_checked_center = editForm.hasOwnProperty("center")
    ? editForm.center
    : false;
  let this_checked_page_break = editForm.hasOwnProperty("pageBreakBefore")
    ? editForm.pageBreakBefore
    : false;
  let this_checked_alternate_form = editForm.hasOwnProperty("alternateForm")
    ? editForm.alternateForm
    : false;
  let canHaveImageSize =
    editForm.element === "Image" || editForm.element === "Camera";
  let {
    canHavePageBreakBefore,
    canHaveAlternateForm,
    canHaveDisplayHorizontal,
    canHaveOptionCorrect,
    canHaveOptionValue,
  } = data;

  // let this_files = props && props.files && props.files.length ? props.files : [];
  // if (this_files.length < 1 || (this_files.length > 0 && this_files[0].id !== '')) {
  //     this_files.unshift({ id: '', file_name: '' });
  // }

  function editElementProp(value, arrayPath = []) {
    updateSelectedField(_.set(deepClone(editForm), arrayPath, value));
    setEditForm(deepClone(_.set(deepClone(editForm), arrayPath, value)));
  }

  const [dragItem, setDragItem] = useState(null);
  const [dragOverItem, setDragOverItem] = useState();

  const dragStart = (position) => {
    setDragItem(position);
  };

  const dragEnter = (position) => {
    setDragOverItem(position);
  };

  function drop(e, key) {
    const copyListItems = [...editForm[key]];
    const dragItemContent = copyListItems[dragItem];
    copyListItems.splice(dragItem, 1);
    copyListItems.splice(dragOverItem, 0, dragItemContent);
    setDragItem(null);
    setDragOverItem(null);
    editForm[key] = copyListItems;
    updateSelectedField(editForm);
  }

  //File upload S3 bucket
  const UploadFilesS3 = async (file) => {
    if (!file.name) {
      return;
    }
    const params = {
      Bucket: process.env.REACT_APP_AWS_S3_BUCKET_NAME,
      Key: `${file.name}`,
      Body: file,
    };
    const { Key } = await s3.upload(params).promise();
    return Key;
  };
  function setConditionalOperation(value, key) {
    editForm.conditionalOperation[key] = value;
    updateSelectedField(editForm);
  }
  useEffect(() => {
    if (editForm.element === "Content") allInputFieldValues();
  }, [editForm]);

  function allInputFieldValues() {
    let allForms = document
      .getElementById("formBuilderView")
      .getElementsByTagName("form");
    if (allForms.length > 0) {
      let formVariables = [];
      for (let i = 0; i < allForms.length; i++) {
        const formElements = Array.from(allForms[i].elements);
        formElements.map((inputs) => {
          if (!["radio", "file", "checkbox"].includes(inputs.type)) {
            formVariables.push({
              type: "choiceitem",
              text: allForms[i].id + "." + inputs.name,
              value: `&nbsp<code>${allForms[i].id + "." + inputs.name
                }</code>&nbsp`,
            });
          }
        });
      }
      setAllInputVariables(formVariables);
    }
  }
  return (
    <div>
      {modalStatus && (
        <div className="editDataInfoModal">
          <label className="heading">
            <span
              className="btnBack"
              hidden={!editSubmitbtnSettings}
              onClick={(e) => {
                setEditSubmitbtnSettings(false);
                setEditSubmitbtnSettingsIndex(null);
              }}
            >
              <i className="fas fa-chevron-left"></i>
            </span>
            {editForm.name
              ? `Field Settings - ${editForm.name} `
              : "Form Settings"}
          </label>
          <button
            className="closeButton"
            type="button"
            title="Close"
            onClick={(e) => closeModal()}
          >
            <img src={crossIcon} />
          </button>
          {!editSubmitbtnSettings ? (
            <>
              {editForm.hasOwnProperty("label") && (
                <>
                  <div className="form-group">
                    {editForm.element == "Paragraph" ? (
                      <>
                        <label>
                          <b>Content</b>
                        </label>
                        <Editor
                          apiKey={
                            "ca86c9gyn7s60sdy77pcofrq0oel2uy3ccxx9izlj7p3k44x"
                          }
                          onInit={(evt, editor) => {
                            editorRef.current = editor;
                          }}
                          value={
                            editForm.label ? JSON.parse(editForm.label) : ""
                          }
                          key={editorKey}
                          init={{
                            selector: "textarea",
                            height: 200,
                            menu: {
                              custom: {
                                title: "custom",
                                items: "basicitem nesteditem toggleitem",
                              },
                            },
                            toolbar:
                              "bold italic link bullist numlist underline | ",
                            font_formats:
                              "Arial=arial,helvetica,sans-serif; Courier New=courier new,courier,monospace; AkrutiKndPadmini=Akpdmi-n",
                            block_formats:
                              "Paragraph=p; Header 1=h1; Header 2=h2; Header 3=h3",
                            plugins: [
                              "image",
                              "help",
                              "link",
                              "codesample",
                              "lists",
                            ],
                            branding: false,
                            content_style:
                              "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",

                            menubar: false,
                          }}
                          onEditorChange={(e, a) => {
                            editElementProp(JSON.stringify(e), ["label"]);
                          }}
                        />
                      </>
                    ) : (
                      <>
                        <label>Label</label>
                        <input
                          value={editForm.label}
                          className="form-control"
                          onChange={(e) =>
                            editElementProp(e.target.value, ["label"])
                          }
                        />
                      </>
                    )}
                  </div>
                  <div className="form-group">
                    {editForm.hasOwnProperty("required") && (
                      <div className="custom-control custom-checkbox">
                        <input
                          id="is-required"
                          className="custom-control-input"
                          type="checkbox"
                          checked={this_checked}
                          value={true}
                          onClick={(e) =>
                            editElementProp(e.target.checked, ["required"])
                          }
                        />
                        &nbsp;
                        <label
                          className="custom-control-label"
                          htmlFor="is-required"
                        >
                          Required
                        </label>
                      </div>
                    )}
                    {editForm.hasOwnProperty("userContentEditable") && (
                      <div className="custom-control custom-checkbox">
                        <input
                          id="is-required"
                          className="custom-control-input"
                          type="checkbox"
                          checked={editForm.userContentEditable}
                          onClick={(e) =>
                            editElementProp(e.target.checked, [
                              "userContentEditable",
                            ])
                          }
                        />
                        &nbsp;
                        <label
                          className="custom-control-label"
                          htmlFor="is-required"
                        >
                          Content Editable
                        </label>
                      </div>
                    )}
                    {editForm.hasOwnProperty("readOnly") && (
                      <div className="custom-control custom-checkbox">
                        <input
                          id="is-read-only"
                          className="custom-control-input"
                          type="checkbox"
                          checked={this_read_only}
                          value={true}
                          onClick={(e) =>
                            editElementProp(e.target.value, [
                              "readOnly",
                              "checked",
                            ])
                          }
                        />
                        &nbsp;
                        <label
                          className="custom-control-label"
                          htmlFor="is-read-only"
                        >
                          Read Only
                        </label>
                      </div>
                    )}
                  </div>
                </>
              )}
              {editForm.hasOwnProperty("title") && (
                <div>
                  <div className="form-group">
                    <label>Title</label>
                    <input
                      value={editForm.title.label}
                      className="form-control"
                      onChange={(e) =>
                        editElementProp(e.target.value, ["title", "label"])
                      }
                    />
                  </div>
                  <div className="form-group">
                    <label>Description</label>
                    <textarea
                      value={editForm.description}
                      className="form-control"
                      onChange={(e) =>
                        editElementProp(e.target.value, ["description"])
                      }
                    />
                  </div>
                  <div className="form-group">
                    <label>Logo</label>
                    {/* <span>{editForm.logo}</span> */}
                    {!editForm.logo ?
                      <input
                        type="file"
                        accept={editForm.logo}
                        className="form-control"
                        onChange={async (e) => {
                          let Key = await UploadFilesS3(e.target.files[0]);
                          editElementProp(Key, ["logo"]);
                          setSelectedFile(e.target.files[0]);
                          setOpenImageEditor(true);

                        }
                        }
                      />
                      :
                      <>
                        <Image data={{ src: editForm.logo }} />
                        <a
                          className="actionBtn"
                          role="button"
                          onClick={() => {
                            editElementProp("", ["logo"]);
                          }}
                        >
                          <img
                            style={{ width: "30px", paddingLeft: "10px" }}
                            src={deleteIcon}
                          />
                        </a>
                      </>
                    }
                  </div>
                  <div className="jsonData">
                    <div className="accordion" id="accordionExample">
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="headingOne">
                          <button
                            className="accordion-button"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseOne"
                            aria-expanded="true"
                            aria-controls="collapseOne"
                          >
                            <span>
                              <img src={arrowRight} />
                            </span>{" "}
                            Post JSON Data to a Website
                          </button>
                        </h2>
                        <div
                          id="collapseOne"
                          className="accordion-collapse collapse show"
                          aria-labelledby="headingOne"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body pt-0">
                            {editForm.formActionSettings.map(
                              (data, referIndex) => {
                                return (
                                  <div className="form-group" key={`choice__${referIndex}`}>
                                    <label>{data.type} Entry Endpoint</label>
                                    <input
                                      value={data.entry_endpoint}
                                      className="form-control"
                                      onChange={(e) =>
                                        editElementProp(e.target.value, [
                                          "formActionSettings",
                                          referIndex,
                                          "entry_endpoint",
                                        ])
                                      }
                                    />
                                  </div>
                                );
                              }
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {editForm.title.pages && editForm.title.pages.length > 0 && (
                    <>
                      <span className="d-block mb-2">
                        <b>Pages</b>
                      </span>
                      <div className="form-group">
                        {[...editForm.title.pages].map((item, index) => {
                          return (
                            <div className="pageBreakSection" key={`${uniqueID(4)}__${index}`}>
                              {index + 1}:{" "}
                              <input
                                className="custom-control-input form-control"
                                type="text"
                                value={item.label}
                                onChange={(e) =>
                                  editElementProp(e.target.value, [
                                    "title",
                                    "pages",
                                    index,
                                    "label",
                                  ])
                                }
                              />
                            </div>
                          );
                        })}
                      </div>
                    </>
                  )}
                </div>
              )}
              {editForm.element === "Content" && (
                <>
                  <div className="form-group">
                    <label>
                      <b>Dynamic Content</b>
                    </label>
                    <Editor
                      apiKey={
                        "ca86c9gyn7s60sdy77pcofrq0oel2uy3ccxx9izlj7p3k44x"
                      }
                      onInit={(evt, editor) => {
                        editorRef.current = editor;
                      }}
                      key={editorKey}
                      value={
                        editForm.DynamicContent
                          ? JSON.parse(editForm.DynamicContent)
                          : ""
                      }
                      init={{
                        selector: "textarea",
                        height: 200,
                        menu: {
                          custom: {
                            title: "custom",
                            items: "basicitem nesteditem toggleitem",
                          },
                        },
                        toolbar:
                          "bold italic link bullist numlist underline myButton | ",
                        font_formats:
                          "Arial=arial,helvetica,sans-serif; Courier New=courier new,courier,monospace; AkrutiKndPadmini=Akpdmi-n",
                        block_formats:
                          "Paragraph=p; Header 1=h1; Header 2=h2; Header 3=h3",
                        plugins: [
                          "image",
                          "help",
                          "link",
                          "codesample",
                          "lists",
                        ],
                        branding: false,
                        content_style:
                          "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",

                        menubar: false,
                        setup: function (editor) {
                          editor.ui.registry.addSplitButton("myButton", {
                            icon: "plus",
                            onAction: function () {
                              // editor.insertContent(
                              //   "<p>You clicked the main button</p>"
                              // );
                            },
                            onItemAction: function (api, value) {
                              editor.insertContent(value);
                            },
                            fetch: function (callback) {
                              var items = [...allInputVariables];
                              callback(items);
                            },
                          });
                        },
                      }}
                      onEditorChange={(e) => {
                        editElementProp(JSON.stringify(e), ["DynamicContent"]);
                      }}
                    />
                  </div>
                </>
              )}
              {editForm.element === "RepeatingSection" && (
                <>
                  <div className="form-group">
                    <label>Item Label</label>
                    <input
                      className="form-control"
                      value={editForm.itemLabel}
                      onChange={(e) =>
                        editElementProp(e.target.value, ["itemLabel"])
                      }
                    />
                  </div>
                  <div className="form-group">
                    <label>Add Button Text</label>
                    <input
                      className="form-control"
                      value={editForm.addButtonText}
                      onChange={(e) =>
                        editElementProp(e.target.value, ["addButtonText"])
                      }
                    />
                  </div>
                  <div className="form-group">
                    <label>Number of Items</label>
                    <div className="row">
                      <div className="col-sm-5">
                        <input
                          id="elementWidth"
                          type="number"
                          className="form-control"
                          defaultValue={
                            editForm.numberofItems.min !== undefined
                              ? editForm.numberofItems.min
                              : 0
                          }
                          onChange={(e) =>
                            editElementProp(e.target.value, [
                              "numberofItems",
                              "min",
                            ])
                          }
                        />
                      </div>
                      <div className="col-sm-1">to</div>
                      <div className="col-sm-5">
                        <input
                          id="elementHeight"
                          type="number"
                          className="form-control"
                          defaultValue={
                            editForm.numberofItems.max !== undefined
                              ? editForm.numberofItems.max
                              : 10
                          }
                          onChange={(e) =>
                            editElementProp(e.target.value, [
                              "numberofItems",
                              "max",
                            ])
                          }
                        />
                      </div>
                    </div>
                  </div>
                </>
              )}
              {editForm.hasOwnProperty("placeholder") && (
                <div className="form-group">
                  <label>Placeholder</label>
                  <input
                    className="form-control"
                    value={editForm.placeholder}
                    onChange={(e) =>
                      editElementProp(e.target.value, ["placeholder"])
                    }
                  />
                </div>
              )}
              {editForm.hasOwnProperty("href") && (
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    defaultValue={editForm.href}
                    onChange={(e) =>
                      editElementProp(e.target.value, ["href", "value"])
                    }
                  />
                </div>
              )}
              {editForm.hasOwnProperty("src") && (
                <div>
                  <div className="form-group">
                    <label className="control-label" htmlFor="srcInput">
                      Upload File&nbsp;
                    </label>
                    {editForm.src ? (
                      <>
                        <span>{editForm.src}</span>
                        <a
                          className="actionBtn"
                          role="button"
                          onClick={() => {
                            editElementProp("", ["src"]);
                          }}
                        >
                          <img
                            style={{ width: "30px", paddingLeft: "10px" }}
                            src={deleteIcon}
                          />
                        </a>
                      </>
                    ) : (
                      <input
                        id="UploadFileInput"
                        type="file"
                        className="form-control"
                        accept={editForm.accept}
                        onChange={async (e) => {
                          if (e.target.files[0].type !== "video/mp4") {
                            setSelectedFile(e.target.files[0]);
                            setOpenImageEditor(true);
                          } else {
                            document
                              .getElementById("upload_image")
                              .classList.remove("hidden");
                            let Key = await UploadFilesS3(e.target.files[0]);
                            editElementProp(Key, ["src"]);
                            document
                              .getElementById("upload_image")
                              .classList.add("hidden");
                          }
                        }}
                      />
                    )}
                    {openImageEditor && selectedFile.name !== undefined && (
                      <ImageEditor
                        file={selectedFile}
                        setSelectedFile={setSelectedFile}
                        modalIsOpen={openImageEditor}
                        setIsOpen={setOpenImageEditor}
                        saveData={(e) => {
                          editElementProp(e, ["src"]);
                        }}
                      />
                    )}
                    <div className="hidden" id="upload_image">
                      <Loader />
                    </div>
                  </div>
                </div>
              )}
              {editForm.element === "TextInput" && (
                <div className="form-group">
                  <label className="control-label">Choose Input Type:</label>
                  {editForm.input_types.map((row, idx) => {
                    return (
                      <div className="custom-control custom-checkbox" key={`row__${idx}`}>
                        <input
                          id={row.id}
                          className="custom-control-input"
                          type="radio"
                          name="input_type"
                          value={row.value}
                          onChange={(e) => {
                            editElementProp(e.target.value, ["type"]);
                          }}
                          checked={editForm.type === row.value}
                        />
                        &nbsp;
                        <label
                          className="custom-control-label"
                          htmlFor={row.id}
                        >
                          {row.text}
                        </label>
                      </div>
                    );
                  })}
                </div>
              )}
              {canHaveImageSize && (
                <div>
                  <div className="form-group">
                    <div className="custom-control custom-checkbox">
                      <input
                        id="do-center"
                        className="custom-control-input"
                        type="checkbox"
                        checked={this_checked_center}
                        value={true}
                        onChange={(e) =>
                          editElementProp(e.target.value, ["center", "checked"])
                        }
                      />
                      &nbsp;
                      <label
                        className="custom-control-label"
                        htmlFor="do-center"
                      >
                        Center?
                      </label>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-3">
                      <label className="control-label" htmlFor="elementWidth">
                        Width:&nbsp;
                      </label>
                      <input
                        id="elementWidth"
                        type="text"
                        className="form-control"
                        defaultValue={editForm.width}
                        onChange={(e) =>
                          editElementProp(e.target.value, ["width", "value"])
                        }
                      />
                    </div>
                    <div className="col-sm-3">
                      <label className="control-label" htmlFor="elementHeight">
                        Height:&nbsp;
                      </label>
                      <input
                        id="elementHeight"
                        type="text"
                        className="form-control"
                        defaultValue={editForm.height}
                        onChange={(e) =>
                          editElementProp(e.target.value, ["height", "value"])
                        }
                      />
                    </div>
                  </div>
                </div>
              )}
              {editForm.element === "FileUpload" && (
                <div>
                  <div className="form-group">
                    <label className="control-label" htmlFor="fileType">
                      Choose Format Type:
                    </label>
                    <select
                      id="fileType"
                      className="form-control"
                      onChange={(e) =>
                        editElementProp(e.target.value, ["fileType", "value"])
                      }
                    >
                      {[
                        {
                          type: "image/*, application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation, video/mp4,video/x-m4v,video/*",
                          typeName: "All File Type",
                        },
                        { type: "image/*", typeName: "Image" },
                        { type: "application/pdf", typeName: "PDF" },
                        {
                          type: "application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                          typeName: "Word",
                        },
                        {
                          type: "application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                          typeName: "Excel",
                        },
                        {
                          type: "application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation",
                          typeName: "Powerpoint",
                        },
                        {
                          type: "video/mp4,video/x-m4v,video/*",
                          typeName: "Videos",
                        },
                      ].map((file, index) => (
                        <option value={file.type} key={index}>
                          {file.typeName}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              )}
              {editForm.element === "Signature" && editForm.readOnly ? (
                <div className="form-group">
                  <label className="control-label" htmlFor="variableKey">
                    Variable Key:
                  </label>
                  <input
                    id="variableKey"
                    type="text"
                    className="form-control"
                    defaultValue={editForm.variableKey}
                    onChange={(e) =>
                      editElementProp(e.target.value, ["variableKey", "value"])
                    }
                  />
                  <p className="help-block">Variable Key Desc.</p>
                </div>
              ) : (
                <div />
              )}

              {canHaveAlternateForm && (
                <div className="form-group">
                  {/*<label className="control-label">alternate-signature-page</label>*/}
                  <div className="custom-control custom-checkbox">
                    <input
                      id="display-on-alternate"
                      className="custom-control-input"
                      type="checkbox"
                      checked={this_checked_alternate_form}
                      value={true}
                      onChange={(e) =>
                        editElementProp(e.target.value, [
                          "alternateForm",
                          "checked",
                        ])
                      }
                    />
                    &nbsp;
                    <label
                      className="custom-control-label"
                      htmlFor="display-on-alternate"
                    >
                      {/* <IntlMessages id={props.showFieldIn} />? */}
                    </label>
                  </div>
                </div>
              )}

              {editForm.hasOwnProperty("step") && (
                <div className="form-group">
                  <div className="form-group-range">
                    <label className="control-label" htmlFor="rangeStep">
                      Step
                    </label>
                    <input
                      id="rangeStep"
                      type="number"
                      className="form-control"
                      defaultValue={editForm.step}
                      onChange={(e) =>
                        editElementProp(e.target.value, ["step", "value"])
                      }
                    />
                  </div>
                </div>
              )}
              {editForm.hasOwnProperty("setInputValueRange") && (
                <>
                  <div className="form-group">
                    <div className="custom-control custom-checkbox">
                      <input
                        className="custom-control-input"
                        type="checkbox"
                        value={editForm.setInputValueRange}
                        onClick={(e) =>
                          editElementProp(!editForm.setInputValueRange, [
                            "setInputValueRange",
                          ])
                        }
                        checked={editForm.setInputValueRange}
                      />
                      &nbsp;
                      <label className="custom-control-label">
                        Set Input Range Value
                      </label>
                    </div>
                  </div>
                  {editForm.setInputValueRange && (
                    <>
                      <div className="form-group">
                        <div className="form-group-range">
                          <label className="control-label" htmlFor="rangeMin">
                            Min
                          </label>
                          <input
                            id="rangeMin"
                            type="number"
                            className="form-control"
                            defaultValue={editForm.min_value}
                            onChange={(e) =>
                              editElementProp(e.target.value, [
                                "min_value",
                                "value",
                              ])
                            }
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="form-group-range">
                          <label className="control-label" htmlFor="rangeMax">
                            Max
                          </label>
                          <input
                            id="rangeMax"
                            type="number"
                            className="form-control"
                            defaultValue={editForm.max_value}
                            onChange={(e) =>
                              editElementProp(e.target.value, [
                                "max_value",
                                "value",
                              ])
                            }
                          />
                        </div>
                      </div>
                    </>
                  )}
                </>
              )}
              {editForm.hasOwnProperty("default_value") && (
                <div className="form-group">
                  <div className="form-group-range">
                    <label className="control-label" htmlFor="defaultSelected">
                      Default Selected
                    </label>
                    <input
                      id="defaultSelected"
                      type="text"
                      className="form-control"
                      defaultValue={editForm.default_value}
                      onChange={(e) =>
                        editElementProp(e.target.value, [
                          "default_value",
                          "value",
                        ])
                      }
                    />
                  </div>
                </div>
              )}
              {editForm.hasOwnProperty("static") && editForm.static && (
                <div className="form-group">
                  <label className="control-label">Text Style</label>
                  <div className="custom-control custom-checkbox">
                    <input
                      id="do-bold"
                      className="custom-control-input"
                      type="checkbox"
                      checked={this_checked_bold}
                      value={true}
                      onClick={(e) =>
                        editElementProp(e.target.checked, ["bold", "checked"])
                      }
                    />
                    <label className="custom-control-label" htmlFor="do-bold">
                      Bold
                    </label>
                  </div>
                  <div className="custom-control custom-checkbox">
                    <input
                      id="do-italic"
                      className="custom-control-input"
                      type="checkbox"
                      checked={this_checked_italic}
                      value={true}
                      onClick={(e) =>
                        editElementProp(e.target.checked, ["italic", "checked"])
                      }
                    />
                    <label className="custom-control-label" htmlFor="do-italic">
                      Italic
                    </label>
                  </div>
                </div>
              )}
              {editForm.showDescription && (
                <div className="form-group">
                  <label
                    className="control-label"
                    htmlFor="questionDescription"
                  >
                    Description
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="questionDescription"
                    defaultValue={editForm.description}
                    onChange={(e) =>
                      editElementProp(e.target.value, ["description", "value"])
                    }
                  />
                </div>
              )}
              {editForm.element === "CheckBoxes" &&
                editForm.hasOwnProperty("options") && (
                  <>
                    <div className="form-group">
                      <input
                        id="typeRadio"
                        className="custom-control-input"
                        type="checkbox"
                        defaultChecked={editForm.typeRadio}
                        value={true}
                        onClick={(e) =>
                          editElementProp(!editForm.typeRadio, ["typeRadio"])
                        }
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="typeRadio"
                      >
                        &nbsp;Set type as radio
                      </label>
                    </div>
                    <div className="form-group">
                      <input
                        id="hasValues"
                        className="custom-control-input"
                        type="checkbox"
                        defaultChecked={editForm.hasValues}
                        value={true}
                        onClick={(e) =>
                          editElementProp(!editForm.hasValues, ["hasValues"])
                        }
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="hasValues"
                      >
                        &nbsp;Set value
                      </label>
                    </div>
                    <div className="form-group">
                      <span className="d-block mb-2">
                        <b>Choice</b>
                      </span>
                      {editForm.options &&
                        editForm.options.length > 0 &&
                        [...editForm.options].map((item, index) => {
                          return (
                            <div
                              className={`choiceSec ${dragOverItem == index ? "active" : ""
                                }`}
                              onDragStart={() => dragStart(index)}
                              onDragEnter={() => dragEnter(index)}
                              onDragEnd={(e) => drop(e, "options")}
                              key={item.id}
                              draggable
                            >
                              <a role="button" type="button">
                                <i className="fa-solid fa-grip"></i>
                              </a>
                              <input
                                className="custom-control-input"
                                type={data.typeRadio ? "radio" : "checkbox"}
                                name={editForm.id}
                                checked={item.checked}
                                value={item.value}
                                onClick={(e) =>
                                  editElementProp(e.target.checked, [
                                    "options",
                                    index,
                                    "checked",
                                  ])
                                }
                              />
                              &nbsp;
                              <input
                                className={`custom-control-input form-control${editForm.hasValues ? " keyField" : ""
                                  }`}
                                type="text"
                                value={item.key}
                                onChange={(e) =>
                                  editElementProp(e.target.value, [
                                    "options",
                                    index,
                                    "key",
                                  ])
                                }
                              />
                              &nbsp;
                              {editForm.hasValues && (
                                <input
                                  className="custom-control-input form-control valueField"
                                  type="text"
                                  value={item.value}
                                  onChange={(e) =>
                                    editElementProp(e.target.value, [
                                      "options",
                                      index,
                                      "value",
                                    ])
                                  }
                                />
                              )}
                              <a
                                role="button"
                                type="button"
                                onClick={() => {
                                  editForm.options.splice(index, 1);
                                  updateSelectedField(editForm);
                                }}
                              >
                                <i className="fa-solid fa-trash"></i>
                              </a>
                              &nbsp;
                              <a
                                role="button"
                                type="button"
                                onClick={() => {
                                  editForm.options.splice(index + 1, 0, {
                                    id: "CheckBoxes_Option_" + uniqueID(6),
                                    value: null,
                                    checked: false,
                                  });
                                  updateSelectedField(editForm);
                                }}
                              >
                                <i className="fa-solid fa-plus"></i>
                              </a>
                              &nbsp;
                            </div>
                          );
                        })}
                    </div>
                  </>
                )}
              {editForm.element === "PageBreak" &&
                editForm.hasOwnProperty("options") && (
                  <>
                    <div className="form-group">
                      <label
                        className="control-label"
                        htmlFor="questionDescription"
                      >
                        Next Button
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="questionDescription"
                        defaultValue={editForm.description}
                        onChange={(e) =>
                          editElementProp(e.target.value, [
                            "PageBreak",
                            "nextButtonLabel",
                          ])
                        }
                      />
                    </div>
                  </>
                )}

              {editForm.element === "DropDown" &&
                editForm.hasOwnProperty("options") && (
                  <>
                    <div className="form-group">
                      <span className="d-block mb-2">
                        <b>Options</b>
                      </span>
                      {editForm.optionType == "static" &&
                        editForm.options &&
                        editForm.options.length > 0 &&
                        [...editForm.options].map((item, index) => {
                          return (
                            <div
                              className={`choiceSec ${dragOverItem == index ? "active" : ""
                                }`}
                              onDragStart={() => dragStart(index)}
                              onDragEnter={() => dragEnter(index)}
                              onDragEnd={(e) => drop(e, "options")}
                              key={item.id}
                              draggable
                            >
                              <a role="button" type="button">
                                <i className="fa-solid fa-grip"></i>
                              </a>
                              <input
                                className="custom-control-input"
                                type="radio"
                                name="Options"
                                value={index}
                                checked={index == editForm.defaultValue}
                                onClick={(e) =>
                                  editElementProp(index, ["defaultValue"])
                                }
                              />
                              &nbsp;
                              <input
                                className="custom-control-input form-control"
                                type="text"
                                value={item.value}
                                onChange={(e) =>
                                  editElementProp(e.target.value, [
                                    "options",
                                    index,
                                    "value",
                                  ])
                                }
                              />
                              &nbsp;
                              <a
                                role="button"
                                type="button"
                                onClick={() => {
                                  editForm.options.splice(index, 1);
                                  updateSelectedField(editForm);
                                }}
                              >
                                <i className="fa-solid fa-trash"></i>
                              </a>
                              &nbsp;
                              <a
                                role="button"
                                type="button"
                                onClick={() => {
                                  editForm.options.splice(index + 1, 0, {
                                    id: "CheckBoxes_Option_" + uniqueID(6),
                                    value: null,
                                    checked: false,
                                  });
                                  updateSelectedField(editForm);
                                }}
                              >
                                <i className="fa-solid fa-plus"></i>
                              </a>
                              &nbsp;
                            </div>
                          );
                        })}
                      {editForm.optionType == "dynamic" && <>Dynamic</>}
                    </div>
                  </>
                )}
              {editForm.element === "Submit" && (
                <>
                  <div className="form-group">
                    <span className="d-block mb-2">
                      <b>Buttons</b>
                    </span>
                    {editForm.buttons &&
                      editForm.buttons.length > 0 &&
                      [...editForm.buttons].map((item, index) => {
                        return (
                          <>
                            <div
                              className={`choiceSec ${dragOverItem == index ? "active" : ""
                                }`}
                              onDragStart={() => dragStart(index)}
                              onDragEnter={() => dragEnter(index)}
                              onDragEnd={(e) => drop(e, "buttons")}
                              key={item.id}
                              draggable
                            >
                              <a role="button" type="button">
                                <i className="fa-solid fa-grip"></i>
                              </a>
                              <span className="custom-control-input form-control">
                                {item.buttonText}
                              </span>
                              <a
                                role="button"
                                style={
                                  editForm.buttons.length <= 1
                                    ? { pointerEvents: "none" }
                                    : {}
                                }
                                type="button"
                                onClick={() => {
                                  editForm.buttons.splice(index, 1);
                                  updateSelectedField(editForm);
                                }}
                              >
                                <i className="fa-solid fa-trash"></i>
                              </a>
                              &nbsp;
                              <a
                                role="button"
                                type="button"
                                onClick={() => {
                                  setEditSubmitbtnSettings(true);
                                  setEditSubmitbtnSettingsIndex(index);
                                }}
                              >
                                <i className="fa-solid fa-edit"></i>
                              </a>
                              &nbsp;
                            </div>
                          </>
                        );
                      })}
                    <a
                      className="actionAdd"
                      role="button"
                      onClick={() => {
                        editForm.buttons.push({ ...AddButtonJson });
                        updateSelectedField(editForm);
                      }}
                    >
                      <i className="fas fa-plus"></i> Add Action
                    </a>

                    <span className="d-block mb-2">
                      <b>Statuses</b>
                    </span>
                    {editForm.statusTypes &&
                      editForm.statusTypes.length > 0 &&
                      [...editForm.statusTypes].map((item, index) => {
                        return (
                          <>
                            <div
                              className={`choiceSec ${dragOverItem == index ? "active" : ""
                                }`}
                              onDragStart={() => dragStart(index)}
                              onDragEnter={() => dragEnter(index)}
                              onDragEnd={(e) => drop(e, "statusTypes")}
                              key={item.id}
                              draggable
                            >
                              <a role="button" type="button">
                                <i className="fa-solid fa-grip"></i>
                              </a>
                              <input
                                className="custom-control-input form-control"
                                type="text"
                                value={item.label}
                                onChange={(e) =>
                                  editElementProp(e.target.value, [
                                    "statusTypes",
                                    index,
                                    "label",
                                  ])
                                }
                              />
                              &nbsp;
                              <a
                                role="button"
                                style={
                                  editForm.statusTypes.length <= 1
                                    ? { pointerEvents: "none" }
                                    : {}
                                }
                                type="button"
                                onClick={() => {
                                  editForm.statusTypes.splice(index, 1);
                                  updateSelectedField(editForm);
                                }}
                              >
                                <i className="fa-solid fa-trash"></i>
                              </a>
                              &nbsp;
                              <input
                                type="color"
                                id="favcolor"
                                name="favcolor"
                                value={item.color}
                                onChange={(e) =>
                                  editElementProp(e.target.value, [
                                    "statusTypes",
                                    index,
                                    "color",
                                  ])
                                }
                              />
                            </div>
                          </>
                        );
                      })}
                    <a
                      className="actionAdd"
                      role="button"
                      onClick={() => {
                        editForm.statusTypes.push({ ...addStatusJson });
                        updateSelectedField(editForm);
                      }}
                    >
                      <i className="fas fa-plus"></i> Add Action
                    </a>
                  </div>
                </>
              )}
              {editForm.element === "RatingScale" && (
                <>
                  <div className="form-group">
                    <span className="d-block mb-2">
                      <b>Questions</b>
                    </span>
                    {editForm.questions &&
                      editForm.questions.length > 0 &&
                      [...editForm.questions].map((item, index) => {
                        return (
                          <div className="choiceSec" key={`choiceSec__${index}`}>
                            <input
                              className="custom-control-input form-control"
                              type="text"
                              value={item.label}
                              onChange={(e) =>
                                editElementProp(e.target.value, [
                                  "questions",
                                  index,
                                  "label",
                                ])
                              }
                            />
                            &nbsp;
                            <a
                              role="button"
                              type="button"
                              style={
                                editForm.questions.length <= 1
                                  ? { pointerEvents: "none" }
                                  : {}
                              }
                              onClick={() => {
                                editForm.questions.splice(index, 1);
                                updateSelectedField(editForm);
                              }}
                            >
                              <i className="fa-solid fa-trash"></i>
                            </a>
                            &nbsp;
                            <a
                              role="button"
                              type="button"
                              onClick={() => {
                                editForm.questions.splice(index + 1, 0, {
                                  id: "CheckBoxes_Option_" + uniqueID(6),
                                  label: `Question ${editForm.questions.length + 1
                                    }`,
                                  checked: false,
                                });
                                updateSelectedField(editForm);
                              }}
                            >
                              <i className="fa-solid fa-plus"></i>
                            </a>
                            &nbsp;
                          </div>
                        );
                      })}
                  </div>
                  <div className="form-group">
                    <span className="d-block mb-2">
                      <b>Ratings</b>
                    </span>
                    <select
                      className="form-control"
                      value={editForm.selectedRatingType}
                      onChange={(e) =>
                        editElementProp(e.target.value, ["selectedRatingType"])
                      }
                    >
                      {editForm.typesOfRating &&
                        editForm.typesOfRating.length > 0 &&
                        [...editForm.typesOfRating].map((item, index) => {
                          return <option value={item.type}  key={`choice__${index}`}>{item.type}</option>;
                        })}
                    </select>
                    {editForm.selectedRatingType == "Custom" && (
                      <>
                        {[
                          ...editForm.typesOfRating.find(
                            (type) => type.type == "Custom"
                          ).structure,
                        ].map((item, index) => {
                          return (
                            <div className="choiceSec" key={`choice__${index}`}>
                              <input
                                className="custom-control-input form-control"
                                type="text"
                                value={item}
                                onChange={(e) =>
                                  editElementProp(e.target.value, [
                                    "typesOfRating",
                                    editForm.typesOfRating.findIndex(
                                      (type) => type.type == "Custom"
                                    ),
                                    "structure",
                                    index,
                                  ])
                                }
                              />
                              &nbsp;
                              <a
                                role="button"
                                type="button"
                                style={
                                  editForm.typesOfRating[
                                    editForm.typesOfRating.findIndex(
                                      (type) => type.type == "Custom"
                                    )
                                  ].structure.length <= 1
                                    ? { pointerEvents: "none" }
                                    : {}
                                }
                                onClick={() => {
                                  editForm.typesOfRating[
                                    editForm.typesOfRating.findIndex(
                                      (type) => type.type == "Custom"
                                    )
                                  ].structure.splice(index, 1);
                                  updateSelectedField(editForm);
                                }}
                              >
                                <i className="fa-solid fa-trash"></i>
                              </a>
                              &nbsp;
                              <a
                                role="button"
                                type="button"
                                onClick={() => {
                                  editForm.typesOfRating[
                                    editForm.typesOfRating.findIndex(
                                      (type) => type.type == "Custom"
                                    )
                                  ].structure.splice(
                                    index + 1,
                                    0,
                                    `Rating ${editForm.typesOfRating[
                                      editForm.typesOfRating.findIndex(
                                        (type) => type.type == "Custom"
                                      )
                                    ].structure.length + 1
                                    }`
                                  );
                                  updateSelectedField(editForm);
                                }}
                              >
                                <i className="fa-solid fa-plus"></i>
                              </a>
                            </div>
                          );
                        })}
                      </>
                    )}
                  </div>
                  <div className="form-group">
                    <span className="d-block mb-2">
                      <b>Default Value</b>
                    </span>
                    <select
                      className="form-control"
                      value={data.defaultRadioValue}
                      onChange={(e) =>
                        editElementProp(e.target.value, ["defaultRadioValue"])
                      }
                    >
                      {editForm.selectedRatingType &&
                        editForm.typesOfRating &&
                        editForm.typesOfRating.length > 0 &&
                        [
                          ...editForm.typesOfRating.find(
                            (type) => type.type == editForm.selectedRatingType
                          ).structure,
                        ].map((item, index) => {
                          return <option value={index} key={`choice__${index}`}>{item}</option>;
                        })}
                    </select>
                  </div>
                </>
              )}
              {editForm.element === "ImageSlider" && (
                <>
                  <div className="form-group">
                    <span className="d-block mb-2">
                      <b>Images</b>
                    </span>
                    {editForm.images &&
                      editForm.images.length > 0 &&
                      [...editForm.images].map((item, index) => {
                        return (
                          <div
                            className={`choiceSec ${dragOverItem == index ? "active" : ""
                              }`}
                            onDragStart={() => dragStart(index)}
                            onDragEnter={() => dragEnter(index)}
                            onDragEnd={(e) => drop(e, "images")}
                            key={item.id}
                            draggable
                          >
                            <a role="button" type="button">
                              <i className="fa-solid fa-grip"></i>
                            </a>
                            <button
                              className="imgUploadBtn"
                              onClick={() => {
                                hiddenFileInput.current.click();
                              }}
                            >
                              {editForm.images[index].src != ""
                                ? editForm.images[index].src
                                : "Upload a file"}
                            </button>
                            <input
                              className="custom-control-input form-control"
                              hidden
                              type="file"
                              accept={editForm.accept}
                              ref={hiddenFileInput}
                              onChange={(e) => {
                                setSelectedFile(e.target.files[0]);
                                setOpenImageEditor(true);
                                setSelectedFileIndex(index);
                              }}
                            />
                            &nbsp;
                            <a
                              role="button"
                              type="button"
                              style={
                                editForm.images.length <= 1
                                  ? { pointerEvents: "none", opacity: "0.5" }
                                  : {}
                              }
                              onClick={() => {
                                editForm.images.splice(index, 1);
                                updateSelectedField(editForm);
                              }}
                            >
                              <i className="fa-solid fa-trash"></i>
                            </a>
                            &nbsp;
                            <a
                              role="button"
                              type="button"
                              onClick={() => {
                                editForm.images.splice(index + 1, 0, {
                                  id: "CheckBoxes_Option_" + uniqueID(6),
                                  src: "",
                                  altName: "Legend",
                                });
                                updateSelectedField(editForm);
                              }}
                            >
                              <i className="fa-solid fa-plus"></i>
                            </a>
                            &nbsp;
                          </div>
                        );
                      })}
                    {openImageEditor && selectedFile.name !== undefined && (
                      <ImageEditor
                        file={selectedFile}
                        setSelectedFile={setSelectedFile}
                        modalIsOpen={openImageEditor}
                        setIsOpen={setOpenImageEditor}
                        saveData={(e) => {
                          // editElementProp(e, 'src', 'value')
                          setSelectedFileIndex(0);
                          editElementProp(e, [
                            "images",
                            selectedFileIndex,
                            "src",
                          ]);
                        }}
                      />
                    )}
                  </div>
                </>
              )}
              {editForm.element === "PageBreak" && (
                <>
                  <div className="form-group">
                    <span className="d-block mb-2">
                      <b>Buttons</b>
                    </span>

                    <div className="custom-control custom-checkbox">
                      <input
                        className="custom-control-input form-control"
                        type="text"
                        value={editForm.buttons[0].label}
                        onChange={(e) =>
                          editElementProp(e.target.value, [
                            "buttons",
                            0,
                            "label",
                          ])
                        }
                      />
                      &nbsp;
                    </div>
                    <div className="form-group">
                      <span className="d-block">Show next button</span>
                      <div className="sectionBtnFiled">
                        {editForm.buttons[0].conditionalOperation.rules.rules
                          .length == 0 && (
                            <button
                              className={
                                editForm.buttons[0].conditionalOperation.type ==
                                  "always"
                                  ? "active"
                                  : ""
                              }
                              onClick={() => {
                                editForm.buttons[0].conditionalOperation.type =
                                  "always";
                                updateSelectedField(editForm);
                                setModalOpen(false);
                              }}
                            >
                              Always
                            </button>
                          )}
                        <button
                          className={
                            editForm.buttons[0].conditionalOperation.type ==
                              "when"
                              ? "active"
                              : ""
                          }
                          onClick={() => {
                            editForm.buttons[0].conditionalOperation.type =
                              "when";
                            setModalOpen(true);
                          }}
                        >
                          When
                        </button>
                        {editForm.buttons[0].conditionalOperation.rules.rules
                          .length > 0 && (
                            <button
                              className="query"
                              title={formatQuery(
                                editForm.buttons[0].conditionalOperation.rules,
                                { format: "spel", parseNumbers: true }
                              )}
                              onClick={() => setModalOpen(true)}
                            >
                              {formatQuery(
                                editForm.buttons[0].conditionalOperation.rules,
                                { format: "spel", parseNumbers: true }
                              )}
                            </button>
                          )}
                      </div>
                    </div>
                    {editForm.buttons[0].conditionalOperation.type ==
                      "when" && (
                        <ConditionalQueryBuilder
                          ModalOpen={ModalOpen}
                          setModalOpen={setModalOpen}
                          updateRule={(value, key) => {
                            editForm.buttons[0].conditionalOperation.rules =
                              value;
                            updateSelectedField(editForm);
                          }}
                          rulesQuery={
                            editForm.buttons[0].conditionalOperation.rules
                          }
                          selectedWidgets={selectedWidgets}
                        />
                      )}
                    <span className="d-block mb-2">
                      <div className="custom-control custom-checkbox">
                        <input
                          id="is-required"
                          className="custom-control-input"
                          type="checkbox"
                          onClick={(e) =>
                            editElementProp(!e.target.checked, [
                              "buttons",
                              1,
                              "hidden",
                            ])
                          }
                        />
                        &nbsp;
                        <label
                          className="custom-control-label"
                          htmlFor="is-required"
                        >
                          Show Back Button
                        </label>
                        {!editForm.buttons[1].hidden && (
                          <input
                            className="custom-control-input form-control"
                            type="text"
                            value={editForm.buttons[1].label}
                            onChange={(e) =>
                              editElementProp(e.target.value, [
                                "buttons",
                                1,
                                "label",
                              ])
                            }
                          />
                        )}
                      </div>
                    </span>
                  </div>
                </>
              )}
              {editForm.element === "Calculation" && (
                <div className="form-group">
                  <label className="control-label">Type:</label>
                  {editForm.input_types.map((row, index) => {
                    return (
                      <div className="custom-control custom-checkbox" key={`choice__${index}`}>
                        <input
                          id={row.id}
                          className="custom-control-input"
                          type="radio"
                          name="input_type"
                          value={row.value}
                          onChange={(e) => {
                            editElementProp(e.target.value, ["type"]);
                          }}
                          checked={editForm.type === row.value}
                        />
                        &nbsp;
                        <label
                          className="custom-control-label"
                          htmlFor={row.id}
                        >
                          {row.text}
                        </label>
                      </div>
                    );
                  })}
                  {(editForm.type == "number" ||
                    editForm.type == "percent") && (
                      <div className="form-group">
                        <label>Decimal</label>
                        <div>
                          <select
                            id="decimal"
                            name="decimal"
                            style={{
                              border:
                                "var(--bs-border-width) solid var(--bs-border-color)",
                              width: "100%",
                              padding: "8px",
                              borderRadius: "8px",
                              backgroundColor: "white",
                            }}
                            onChange={(e) => {
                              editElementProp(e.target.value, ["decimal"]);
                            }}
                          >
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                            <option value="6">6</option>
                            <option value="7">7</option>
                            <option value="8">8</option>
                            <option value="9">9</option>
                            <option value="10">10</option>
                          </select>
                        </div>
                      </div>
                    )}
                </div>
              )}
              {editForm.hasOwnProperty("calculation") && (
                <div className="form-group">
                  <label>Calculation</label>
                  <div className="position-relative">
                    <TextAreaWithSuggestions selectedWidgets={selectedWidgets} inputValProp={editForm?.calculation}
                                             editElementProp={editElementProp} isTextAreaInput={false} />
                    <i
                        className="fa-solid fa-bolt-lightning"
                        style={{
                          right: 0,
                          top: 0,
                          cursor: "pointer",
                          position: "absolute",
                          padding: "10px 14px"
                        }}
                        onClick={() => {
                          setCalculationModalOpen(true);
                        }}
                    ></i>
                  </div>
                  {calculationModalOpen === true && (
                      <CustomDialog
                          ModalOpen={calculationModalOpen}
                          setModalOpen={setCalculationModalOpen}
                          updateRule={setConditionalOperation}
                          rulesQuery={editForm.conditionalOperation.rules}
                          selectedWidgets={selectedWidgets}
                          editForm={editForm}
                          editElementProp={editElementProp}
                          heading="Calculation"
                      />
                  )}
                </div>
              )}
              {editForm.hasOwnProperty("helptext") && (
                <div className="form-group">
                  <label>Help Text</label>
                  <input
                    className="form-control"
                    value={editForm.helptext}
                    onChange={(e) =>
                      editElementProp(e.target.value, ["helptext"])
                    }
                  />
                </div>
              )}
              {editForm.hasOwnProperty("conditionalOperation") && (
                <>
                  <div className="form-group">
                    <span className="d-block">Show This Field</span>
                    <div className="sectionBtnFiled">
                      {editForm.conditionalOperation.rules.rules.length ==
                        0 && (
                          <button
                            className={
                              editForm.conditionalOperation.type == "always"
                                ? "active"
                                : ""
                            }
                            onClick={() => {
                              setConditionalOperation("always", "type");
                              setModalOpen(false);
                            }}
                          >
                            Always
                          </button>
                        )}
                      <button
                        className={
                          editForm.conditionalOperation.type == "when"
                            ? "active"
                            : ""
                        }
                        onClick={() => {
                          setConditionalOperation("when", "type");
                          setModalOpen(true);
                        }}
                      >
                        When
                      </button>
                      {editForm.conditionalOperation.rules.rules.length > 0 && (
                        <button
                          className="query"
                          title={formatQuery(
                            editForm.conditionalOperation.rules,
                            { format: "spel", parseNumbers: true }
                          )}
                          onClick={() => setModalOpen(true)}
                        >
                          {formatQuery(editForm.conditionalOperation.rules, {
                            format: "spel",
                            parseNumbers: true,
                          })}
                        </button>
                      )}
                    </div>
                  </div>
                  {editForm.conditionalOperation.type == "when" && (
                    <ConditionalQueryBuilder
                      ModalOpen={ModalOpen}
                      setModalOpen={setModalOpen}
                      updateRule={setConditionalOperation}
                      rulesQuery={editForm.conditionalOperation.rules}
                      selectedWidgets={selectedWidgets}
                    />
                  )}
                </>
              )}
              {(editForm.element === "ContactInformation" || editForm.element === "Signature") && (
                <>
                  <div className="form-group">
                    {/* <span className="d-block mb-2"><b>Defalult Values</b></span> */}
                    <div className="row">
                      <div className="col-md-4">
                        {/* <small><strong>Field</strong></small> */}
                      </div>
                      <div className="col-md-8">
                        <small>
                          <strong>Default Value</strong>
                        </small>
                      </div>
                    </div>
                    {editForm.fields &&
                      Object.keys(editForm.fields).length > 0 &&
                      Object.keys(editForm.fields).map((field) => {
                        return (
                          <div className="form-group row" key={field.id}>
                            <div className="col-md-4">
                              <small>{editForm.fields[field].name}:</small>
                            </div>
                            <div className="col-md-8">
                              <input
                                className="custom-condivol-input form-control"
                                type="text"
                                value={editForm.fields[field].value}
                                onChange={(e) => {
                                  editElementProp(e.target.value, [
                                    "fields",
                                    field,
                                    "value",
                                  ]);
                                }}
                              />
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </>
              )}
              {editForm.hasOwnProperty("limitQuantity") && (
                <>
                  <div className="form-group">
                    <span className="d-block">Limit Quantity</span>
                    <div className="sectionBtnFiled">
                      {editForm.limitQuantity.rules.rules.length == 0 && (
                        <button
                          className={
                            editForm.limitQuantity.type == "NoDuplicates"
                              ? "active"
                              : ""
                          }
                        >
                          No Duplicates
                        </button>
                      )}
                      <button
                        className={
                          editForm.limitQuantity.type == "SpecificQuantity"
                            ? "active"
                            : ""
                        }
                      >
                        Specific Quantity
                      </button>
                      <button
                        className={
                          editForm.limitQuantity.type == "never" ? "active" : ""
                        }
                      >
                        Never
                      </button>
                    </div>
                  </div>
                  {/* {editForm.conditionalOperation.type == "when" && (
                    <ConditionalQueryBuilder
                      ModalOpen={ModalOpen}
                      setModalOpen={setModalOpen}
                      updateRule={setConditionalOperation}
                      rulesQuery={editForm.conditionalOperation.rules}
                      selectedWidgets={selectedWidgets}
                    />
                  )} */}
                </>
              )}
              {editForm.hasOwnProperty("customError") && (
                <>
                  <div className="form-group">
                    <span className="d-block">Show Custom Error</span>
                    <div className="sectionBtnFiled">
                      <button
                        className={
                          editForm.limitQuantity.type == "when" ? "active" : ""
                        }
                      >
                        When
                      </button>
                      <button
                        className={
                          editForm.limitQuantity.type == "never" ? "active" : ""
                        }
                      >
                        Never
                      </button>
                    </div>
                  </div>
                  {/* {editForm.conditionalOperation.type == "when" && (
                    <ConditionalQueryBuilder
                      ModalOpen={ModalOpen}
                      setModalOpen={setModalOpen}
                      updateRule={setConditionalOperation}
                      rulesQuery={editForm.conditionalOperation.rules}
                      selectedWidgets={selectedWidgets}
                    />
                  )} */}
                </>
              )}
            </>
          ) : (
            <>
              <div className="form-group">
                <label className="control-label">ActionName</label>
                <input
                  type="text"
                  className="form-control"
                  value={
                    editForm.buttons[editSubmitbtnSettingsIndex].actionName
                  }
                  onChange={(e) =>
                    editElementProp(e.target.value, [
                      "buttons",
                      editSubmitbtnSettingsIndex,
                      "actionName",
                    ])
                  }
                />
                <label className="control-label">Button Text</label>
                <input
                  type="text"
                  className="form-control"
                  value={
                    editForm.buttons[editSubmitbtnSettingsIndex].buttonText
                  }
                  onChange={(e) =>
                    editElementProp(e.target.value, [
                      "buttons",
                      editSubmitbtnSettingsIndex,
                      "buttonText",
                    ])
                  }
                />
                <label className="control-label">Confirmation</label>
                <textarea
                  type="text"
                  className="form-control"
                  value={
                    editForm.buttons[editSubmitbtnSettingsIndex]
                      .confirmationMessage
                  }
                  onChange={(e) =>
                    editElementProp(e.target.value, [
                      "buttons",
                      editSubmitbtnSettingsIndex,
                      "confirmationMessage",
                    ])
                  }
                />

                <label className="control-label">Change Status To</label>
                <select
                  type="text"
                  className="form-control"
                  value={
                    editForm.buttons[editSubmitbtnSettingsIndex].changeStatusTo
                  }
                  onChange={(e) =>
                    editElementProp(e.target.value, [
                      "buttons",
                      editSubmitbtnSettingsIndex,
                      "changeStatusTo",
                    ])
                  }
                >
                  <option value="">No Change</option>
                  {editForm.statusTypes.map((data, index) => {
                    return <option key={`choice__${index}`}>{data.label}</option>;
                  })}
                </select>
                <div className="form-group">
                  <span className="d-block">Show This Field</span>
                  <div className="sectionBtnFiled">
                    {editForm.buttons[editSubmitbtnSettingsIndex]
                      .conditionalOperation.rules.rules.length == 0 && (
                        <button
                          className={
                            editForm.buttons[editSubmitbtnSettingsIndex]
                              .conditionalOperation.type == "always"
                              ? "active"
                              : ""
                          }
                          onClick={() => {
                            editElementProp("always", [
                              "buttons",
                              editSubmitbtnSettingsIndex,
                              "conditionalOperation",
                              "type",
                            ]);
                            setModalOpen(false);
                          }}
                        >
                          Always
                        </button>
                      )}
                    <button
                      className={
                        editForm.buttons[editSubmitbtnSettingsIndex]
                          .conditionalOperation.type == "when"
                          ? "active"
                          : ""
                      }
                      onClick={() => {
                        editElementProp("when", [
                          "buttons",
                          editSubmitbtnSettingsIndex,
                          "conditionalOperation",
                          "type",
                        ]);

                        setModalOpen(true);
                      }}
                    >
                      When
                    </button>
                    {editForm.buttons[editSubmitbtnSettingsIndex]
                      .conditionalOperation.rules.rules.length > 0 && (
                        <button
                          className="query"
                          title={formatQuery(
                            editForm.buttons[editSubmitbtnSettingsIndex]
                              .conditionalOperation.rules,
                            { format: "spel", parseNumbers: true }
                          )}
                          onClick={() => setModalOpen(true)}
                        >
                          {formatQuery(
                            editForm.buttons[editSubmitbtnSettingsIndex]
                              .conditionalOperation.rules,
                            { format: "spel", parseNumbers: true }
                          )}
                        </button>
                      )}
                  </div>
                </div>
                {editForm.buttons[editSubmitbtnSettingsIndex]
                  .conditionalOperation.type == "when" && (
                    <ConditionalQueryBuilder
                      ModalOpen={ModalOpen}
                      setModalOpen={setModalOpen}
                      updateRule={(e) => {
                        editElementProp(e, [
                          "buttons",
                          editSubmitbtnSettingsIndex,
                          "conditionalOperation",
                          "type",
                        ]);
                      }}
                      rulesQuery={
                        editForm.buttons[editSubmitbtnSettingsIndex]
                          .conditionalOperation.rules
                      }
                      selectedWidgets={selectedWidgets}
                    />
                  )}
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
}

export default EditFieldsModal;
