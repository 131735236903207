import { useEffect, useState } from 'react';
import { QueryBuilderDnD } from '@react-querybuilder/dnd';
import * as ReactDnD from 'react-dnd';
import * as ReactDndHtml5Backend from 'react-dnd-html5-backend';
import { defaultValidator, QueryBuilder } from 'react-querybuilder';
import 'react-querybuilder/dist/query-builder.scss';
import './style.css';
import { QueryBuilderBootstrap } from '@react-querybuilder/bootstrap';
import 'bootstrap-icons/font/bootstrap-icons.scss';
import 'bootstrap/scss/bootstrap.scss';
import Modal from 'react-modal';
import crossIcon from '../../assets/img/iconCross.svg';
import { Widgets } from '../../shared/constants/widgetJson';


const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: '100%',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    },
};
export const validator = (r) => !!r.value;

const initialQuery = { combinator: 'and', rules: [] };

function ConditionalQueryBuilder({ ModalOpen, setModalOpen, updateRule, rulesQuery, selectedWidgets }) {
    const [modalIsOpen, setIsOpen] = useState(false);
    const [query, setQuery] = useState(initialQuery);
    useEffect(() => { setIsOpen(ModalOpen) }, [ModalOpen]);
    useEffect(() => {
        setQuery(rulesQuery.rules !== [] ? rulesQuery : initialQuery)
    }, [rulesQuery])

    const [fields, setFields] = useState([]);
    useEffect(() => {
        if (selectedWidgets.length > 0) {
            function queryBuilderLoop(row) {
                let widgetsList = []
                row.map((data) => {
                    if (data.hasOwnProperty("element") && [...Object.keys(Widgets)].includes(data.element) && data.hasOwnProperty("valueEditorType") && data.hasOwnProperty("inputType")) {
                        widgetsList.push(data)
                    }
                    if (data.hasOwnProperty("childComponent") && data.childComponent.length > 0) {
                        widgetsList.push(...queryBuilderLoop(data.childComponent))
                    }
                })
                return widgetsList
            }
            let response = queryBuilderLoop(selectedWidgets).map((row) => {
                let Json = {
                    name: row.id,
                    label: row.label !== "" ? row.label : row.id,
                    valueEditorType: row.valueEditorType, //'text' | 'select' | 'checkbox' | 'radio' | 'textarea' | 'switch' | 'multiselect'
                }
                if (row.hasOwnProperty("options") || row.hasOwnProperty("questions")) {
                    if (Json.valueEditorType == "multiselect") {
                        Json["values"] = row.questions.map((question) => {
                            return ({ label: question.label, options: row.typesOfRating.find((type) => type.type == row.selectedRatingType).structure.map((s, i) => ({ name: `${question.label}_${i}`, label: s })) })
                        })
                    } else {
                        Json["values"] = row.options.map((option) => { ; return { name: option.key ? option.key : option.value, label: option.value ? option.value : option.key } })
                    }
                }
                return Json
            })
            setFields(response)
        }
    }, [selectedWidgets])


    function closeModal() {
        setIsOpen(false);
        setModalOpen(false)
    }

    function saveRules() {
        if (query.hasOwnProperty("rules")) {
            updateRule(query, "rules")
            setIsOpen(false);
            setModalOpen(false)
        }
    }

    return (

        <div>
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Example Modal"
            >
                <div className="modalHeader">
                    <h4>Visible When...</h4>
                    <button className="btn btn-closeModal" onClick={() => { closeModal() }}>
                        <img src={crossIcon} />
                    </button>
                </div>
                <QueryBuilderDnD dnd={{ ...ReactDnD, ...ReactDndHtml5Backend }}>
                    <QueryBuilderBootstrap>
                        <QueryBuilder
                            fields={fields}
                            query={query}
                            onQueryChange={(q) => setQuery(q)}
                            addRuleToNewGroups
                            listsAsArrays
                            resetOnOperatorChange
                            showCombinatorsBetweenRules
                            validator={defaultValidator}
                            controlClassnames={{ queryBuilder: 'queryBuilder-branches' }}
                        />
                    </QueryBuilderBootstrap>
                </QueryBuilderDnD>

                <div className="btnSection text-end p-3">
                    <button className="btn btn-save" onClick={() => { saveRules() }}>Save</button>
                </div>
            </Modal >
        </div >
    );
};
export default ConditionalQueryBuilder;
