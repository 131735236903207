import {isEmpty} from "./isEmpty";
import * as _ from "lodash";

export const convertToUrl = (inputString) => {
  // Check if the inputString already starts with a protocol
  if (/^(https?|ftp):\/\//i.test(inputString)) {
    return inputString; // Already a valid URL
  } else {
    // If not, prepend "http://" and return the modified string
    return "http://" + inputString;
  }
}

export const removeSpace = (inputString) => !isEmpty(inputString) ? inputString.replace(/ /g, '_').toLowerCase() : inputString;
export const addSpace = (inputString) => !isEmpty(inputString) ? inputString.replace(/_/g, ' ').toLowerCase() : inputString;
export const removeAfter = (inputString, separator) => !isEmpty(inputString) ? inputString.split(separator)[0] : inputString
export const removeHttp = (inputString) => !isEmpty(inputString) ? inputString.replace(/^https?:\/\//, "") : inputString;
export const evaluateExpression = (data, stringValues) => {
  try {
    let expression = convertStringToExpression(data?.calculation)
    const returnType = data?.type || "number"
    const decimalPlace = data?.decimal || 0
    if (expression.startsWith("=")) {
      expression = expression.slice(1)
    }

    const replacedExpression = expression.replace(/(\$\w+)/g, (match, stringId) => {
      const id = stringId.slice(1);
      return stringValues[id] !== undefined ? typeof stringValues[id] === "object" ? _.reduce(stringValues[id], (sum, n) => {
        return sum + _.toNumber(n);
      }, 0) : _.toNumber(stringValues[id]) : match;
    });

    try {
      const result = eval(replacedExpression);
      return returnType === "percent" ? `${result*100}%` : returnType === "currency" ? `$${result.toFixed(2)}` : result.toFixed(decimalPlace);
    } catch (error) {
      //console.error('Error evaluating expression:', error);
      return null;
    }
  } catch (e) {
  }
};

export const convertStringToExpression = (inputString) => {
  return !isEmpty(inputString) ? inputString.replace(/\$[^_]*__/g, '$') : "";
}