import editIcon from "../../assets/img/edit.svg";
import deleteIcon from "../../assets/img/bin.svg";
import pageIcon from "../../assets/img/pageIcon.svg";
import crossIcon from '../../assets/img/iconCross.svg';
import editClone from '../../assets/img/iconClone.svg';
import "./style.css";
import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { apiGetMethod, apiDeleteMethod, apiPostMethod } from "../../api/rest";
import { allApiUrl } from "../../api/apiRoute"
import Loader from "../Loader";
import Modal from 'react-modal';
import toastMessage from "../ToastMessage"

function FormListing() {
    const [formList, setFormList] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [openDeleteModal, setOpenDeleteModal] = useState(false)
    const [selectedForm, setSelectedForm] = useState({ id: null, title: "" })
    const navigate = useNavigate();

    useEffect(() => {
        fetchFormList();
    }, [])
    function fetchFormList() {
        setIsLoading(true)
        apiGetMethod(allApiUrl.Form).then(({ data, error }) => {
            setIsLoading(false)
            setFormList(data.data)
        })
    }
    function removeForm(id) {
        apiDeleteMethod(allApiUrl.Form + "/" + id).then((response) => {
            fetchFormList();
        })
    }

    async function createFormClone(index) {
        let formSetting = JSON.parse(JSON.stringify(formList[index].formSettings))
        formSetting.title.label = "Cloned " + formSetting.title.label
        var Object = {
            created_date: new Date().toString(),
            formSettings: formSetting,
            structure: formList[index].structure,
            submitSettings: formList[index].submitSettings
        }
        const newArray = [
            ...formList.slice(0, index),
            Object,
            ...formList.slice(index)
        ];
        setFormList(newArray);
        await apiPostMethod(allApiUrl.Form, Object);
        await apiGetMethod(allApiUrl.Form).then(({ data, error }) => {
            data.data.map((item) => {
                if (item.formSettings.title.label == Object.formSettings.title.label) {
                    navigate(`/form-builder/form/${item._id}`)
                }
            })
        })
    }
    function DeleteModal(id) {

        const customStyles = {
            content: {
                top: '50%',
                left: '50%',
                right: '100%',
                bottom: 'auto',
                marginRight: '-30%',
                transform: 'translate(-50%, -50%)',
            },
        };
        function CloseModal() {
            setOpenDeleteModal(false)
        }
        return (

            <div>
                <Modal
                    isOpen={openDeleteModal}
                    onRequestClose={() => CloseModal()}
                    style={customStyles}
                    contentLabel="Delete Modal"
                >

                    <div className="modalHeader">
                        <h4>Delete Form</h4>
                        <button className="btn btn-closeModal" onClick={() => { setOpenDeleteModal(false) }}>
                            <img src={crossIcon} />
                        </button>
                    </div>
                    <div className="saveModalBody">
                        <p>Are you sure you want to delete <b>"{selectedForm.title}"</b>?</p>
                    </div>
                    <div className="btnSection text-end p-3">
                        <button className="btn btn-save" type="button" onClick={() => { CloseModal() }} >Cancel</button>
                        <button className="btn btn-save" onClick={() => { removeForm(selectedForm.id); CloseModal() }}>Delete</button>
                    </div>
                </Modal>
            </div >
        )
    }
    return (
        <>
            {formList.length ?
                <>
                    <tr>
                        <th>Title</th>
                        <th>Created Date</th>
                        <th>Actions</th>
                    </tr>
                    <tr>
                        <td colSpan="3" className="form-heading">JSA Forms</td>
                    </tr>
                    {formList.map((row, index) => {
                        return <tr key={`row__${index}`}>
                            <td>{row.formSettings.title.label}</td>
                            <td>{row.created_date}</td>
                            <td>
                                <a title="Clone" className="actionBtn" role="button" onClick={() => createFormClone(index)} >
                                    <img src={editClone} />
                                </a>
                                <a title="Edit" className="actionBtn" role="button" href={`form-builder/form/${row._id}`} >
                                    <img src={editIcon} />
                                </a>
                                <a title="Preview" className="actionBtn" role="button" href={`${row._id}`} >
                                    <img src={pageIcon} />
                                </a>
                                <a title="Remove" className="actionBtn" role="button" onClick={() => { setSelectedForm({ id: row._id, title: row.formSettings.title.label }); setOpenDeleteModal(true) }} >
                                    <img src={deleteIcon} />
                                </a>
                            </td>
                        </tr>
                    })}
                </>
                :
                <tr>
                    <td></td>
                    <td>
                        {isLoading ? <Loader /> : <div className="text-center">No Records</div>}
                    </td>
                    <td></td>
                </tr>
            }
            {openDeleteModal && <DeleteModal />}
        </>
    );
}

export default FormListing;