function Footer({ formSubmitSettings }) {
    return (
        <div className="row" >
            <div className="col-lg-12" >
                <div className="form-group">
                    <div className="btnGroup">
                        {formSubmitSettings.buttons.map((button, index) => {
                            return <button key={`choice__${index}`} className="btn btn-secondary me-2" onClick={() => { }}>{button.buttonText}</button>
                        })}
                    </div>
                </div>
            </div>

        </div >
    )
}
export default Footer;
