import { useState, memo, useEffect } from "react";
import "./style.css";
import * as _ from "lodash";
import { uniqueID, deepClone } from "../../shared/functions";
import { isEmpty } from "../../shared/functions/isEmpty";
import { ImageAvatar } from "../../shared/constants/widgetsHtml";

function FormHeader({
  hideIcons,
  activePage = undefined,
  selectedWidgets,
  setSelectedWidget,
  setFormSettings,
  formSettings,
  setIsOpen,
}) {
  const [showIcons, setShowIcons] = useState(false);
  let count = 1;
  useEffect(() => {
    if (selectedWidgets.length > 0)
      selectedWidgets.map((row) => {
        if (row.childComponent[0].element == "PageBreak") {
          count += 1;
        }
      });
  }, [selectedWidgets]);

  let newArray = [];
  if (
    count > 1 &&
    formSettings.title.pages &&
    formSettings.title.pages.length !== count
  ) {
    if (count > 1) {
      newArray =
        formSettings.title.pages == []
          ? [...new Array(count)]
          : formSettings.title.pages;
      newArray = [...new Array(count)].map((i, index) => {
        if (newArray[index] !== undefined) {
          return newArray[index];
        }
        return { label: `page ${index + 1}` };
      });
    } else {
      newArray = [];
    }
    formSettings.title.pages = newArray;
    setFormSettings(formSettings);
  }
  if (
    count == 1 &&
    formSettings.title.pages &&
    formSettings.title.pages.length !== 0
  ) {
    formSettings.title.pages = [];
    setFormSettings(formSettings);
  }

  return (
    <div
      className="row"
      onMouseEnter={() => setShowIcons(true)}
      onMouseLeave={() => setShowIcons(false)}
    >
      <div className="col-lg-12">
        <div className="form-group">
          <span
            hidden={hideIcons}
            className={`icons${showIcons ? " show" : ""}`}
          >
            <a
              role="button"
              type="button"
              onClick={() => {
                setSelectedWidget(deepClone(formSettings));
                setIsOpen(true);
              }}
            >
              <i className="fa-regular fa-pen-to-square"></i> <br /> Edit
            </a>
            {/* <a
                            role="button"
                            type="button"
                            onClick={() => [
                                //   props.setCopyWidgetJson({ ...row }),
                                setCopyWidgetJson({ element: formSettings.title.label })
                            ]}
                        >
                            <i className="fa-solid fa-copy"></i>
                            <br /> Copy
                        </a> */}
          </span>
          {/* {<span className={`rowId ${showIds ? "show" : ""}`}>{row.id}</span>} */}
          <div key={"h2" + formSettings.title.id} id={formSettings.title.id}>
            <h2
              style={{
                fontWeight: formSettings.title.bold ? "bold" : "",
                fontStyle: formSettings.title.italic ? "italic" : "",
                display: "flex",
                alignItems: "center",
              }}
            >
              {!isEmpty(formSettings.logo) && (
                <ImageAvatar data={{ src: formSettings.logo }} />
              )}
              <span className="ms-3">
                {formSettings.title.label ? formSettings.title.label : "Title"}
              </span>
            </h2>
            {!isEmpty(formSettings.description) && (
              <p>{formSettings.description}</p>
            )}
          </div>
          {count > 1 && (
            <div className="pagesSection">
              {formSettings.title.pages &&
                formSettings.title.pages.map((i, index) => {
                  return (
                    <a
                      key={`a__${index}`}
                      className={activePage === index ? "active" : ""}
                    >
                      <span>{index + 1}</span>
                      {i.label}
                    </a>
                  );
                })}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
export default memo(FormHeader);
