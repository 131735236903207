import { toast } from "react-toastify";
const customId = "custom-id-yes";

function toastMessages(messageType, message) {
    if (message) {
        if (messageType === "success") {
            toast.dismiss();
            toast.success(message, {
                toastId: customId,
                // generateCustomID()
            });
        } else if (messageType === "dismiss") {
            toast.dismiss();
        } else if (messageType === "warn") {
            toast.dismiss();
            toast.warn(message, {
                toastId: customId,
                //  generateCustomID()
            });
        } else {
            // toast.dismiss()
            toast.error(message, {
                toastId: customId,
                // generateCustomID()
            });
        }
    }
}

export default toastMessages;
