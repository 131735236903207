import './style.css';
import { useParams } from "react-router-dom";
import { useEffect } from 'react';
import { apiGetMethod } from "../../api/rest";
import { allApiUrl } from "../../api/apiRoute"
import { useState } from 'react';
import PreviewForm from "../../components/RenderPreviewForm"



function BuildForm() {
    let { id } = useParams();
    const [jsonData, setJsonData] = useState({})
    function getformDetails() {
        apiGetMethod(`${allApiUrl.Form}/${id}`).then((response) => {
            setJsonData(response.data.data)
        })
    }
    useEffect(() => {
        getformDetails();
    }, [])
    function onChangeFields(props) {
        console.log(props)
    }
    return (
        <>
            <div className="deployedFormOuter">
                <div className="container">
                    <div className="deployedForm">
                        {jsonData.structure !== undefined && <PreviewForm type={"id"} jsonData={jsonData} />}
                    </div>
                </div>
            </div>
        </>
    );
}

export default BuildForm;
