/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState, memo } from "react";
import "./style.css";
import * as _ from "lodash";
import EditFieldsModal from "../EditFieldsModal";
import {
  Widgets,
  Layout,
  Media,
  Submit,
  FormSettings,
} from "../../shared/constants/widgetJson";
import * as widgetsHtml from "../../shared/constants/widgetsHtml";
import { apiPostMethod, apiGetMethod, apiPutMethod } from "../../api/rest";
import { allApiUrl } from "../../api/apiRoute";
import { useParams, useNavigate } from "react-router-dom";
import toastMessage from "../ToastMessage";
import { uniqueID, deepClone } from "../../shared/functions";
import Modal from "react-modal";
import PreviewModal from "../PreviewModal";
import Loader from "../Loader";
import crossIcon from "../../assets/img/iconCross.svg";
import Logo from "../../assets/img/logo.svg";
import menuIcon from "../../assets/img/menuIcon.svg";
import { isEmpty } from "../../shared/functions/isEmpty";
import { Avatar } from "@mui/material";
import { ImageAvatar } from "../../shared/constants/widgetsHtml";
import FormHeader from "./formheader";

function ReactFormBuilder() {
  let { form, id } = useParams();
  const navigate = useNavigate();

  //form data variables
  const [selectedWidgets, setSelectedWidgets] = useState([]);
  const [formSubmitSettings, setFormSubmitSettings] = useState(Submit);
  const [formSettings, setFormSettings] = useState(FormSettings);

  const [selectedWidget, setSelectedWidget] = useState({});
  const [modalIsOpen, setIsOpen] = useState(false);
  const [subForm, setSubForm] = useState([]);
  const [moveWidgets, setMoveWidgets] = useState(false);
  const [showSubForm, setShowSubForm] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState([0, 0]);
  const [saveAsSubForm, setSaveAsSubForm] = useState(false);
  const [saveFormStatus, setSaveFormStatus] = useState(false);
  const [openSaveAsModal, setOpenSaveAsModal] = useState(false);
  const [categoriesList, setCategoriesList] = useState([]);
  const [selectedCategoryId, setSelectedCategoryId] = useState("");
  const [subFormFilter, setSubFormFilter] = useState({
    category: { _id: undefined, name: "" },
    string: "",
  });
  const [activeWidgetId, setActiveWidgetId] = useState({
    activeId: "",
    previousActiveId: [],
  });
  const [showIds, setShowIds] = useState(false);
  const [copyWidgetJson, setCopyWidgetJson] = useState({});
  const [openPreviewModal, setOpenPreviewModal] = useState(false);
  const [showSidebar, setShowSidebar] = useState(false);
  const [activeDropBox, setActiveDropBox] = useState([]);
  const [fetchingData, setFetchingData] = useState(
    id && id.length > 0 ? true : false
  );
  useEffect(() => {
    if (copyWidgetJson.hasOwnProperty("element")) {
      localStorage.setItem(
        "copyData",
        JSON.stringify(deepClone(copyWidgetJson))
      );
    }
  }, [copyWidgetJson]);
  let dragErrorStatus = false;
  const onChangeFields = (item, e, opt = null) => {
    // console.log("onChangeFields ===>", e);
  };
  function fetchSubForms() {
    apiGetMethod(allApiUrl.SubForm).then((response) => {
      let newData = response.data.data.map((row) => {
        let jsonData = deepClone(Layout["Section"]);
        jsonData.childComponent = row.structure;
        jsonData.label = row.formSettings.title.label;
        jsonData.category = row.category;
        return jsonData;
      });
      setSubForm(newData);
    });
  }
  function getformDetails() {
    setFetchingData(true);
    let url = "";
    if (form == "form") {
      url = allApiUrl.Form;
    } else {
      url = allApiUrl.SubForm;
    }
    apiGetMethod(`${url}${id !== undefined ? "/" + id : ""}`).then(
      (response) => {
        setSelectedWidgets(response.data.data.structure);
        setFormSubmitSettings(response.data.data.submitSettings);
        if (response.data.data.category) {
          setSelectedCategoryId(response.data.data.category);
        }
        setFormSettings((prevData) => {
          return {
            ...prevData,
            ...response.data.data.formSettings,
          };
        });
        setTimeout(() => {
          setFetchingData(false);
        }, 1000);
      }
    );
  }

  useEffect(() => {
    fetchSubForms();
    if (id !== undefined) {
      getformDetails();
    }
    window.addEventListener("scroll", (event) => {
      if (window.pageYOffset > 10) {
        setMoveWidgets(true);
      } else {
        setMoveWidgets(false);
      }
    });
    if (form == "subform") {
      setSaveAsSubForm(true);
    }
    apiGetMethod(allApiUrl.Categories).then((response) => {
      setCategoriesList(response.data.data);
    });
    if (localStorage.getItem("copyData")) {
      setCopyWidgetJson(JSON.parse(localStorage.getItem("copyData")));
    }
  }, []);

  function handleDragOver(e) {
    e.preventDefault();
  }

  function handleMoveWidgetOnDrag(e, json, pathArray) {
    e.dataTransfer.setData(
      "moveWidget",
      JSON.stringify({ json: json, path: pathArray })
    );
  }
  function removeWidgetAfterDrop(pathArray) {
    if (!dragErrorStatus) {
      if (selectedWidgets[pathArray[0]].childComponent.length > 1) {
        selectedWidgets[pathArray[0]].childComponent.splice(pathArray[1], 1);
      } else {
        selectedWidgets.splice(pathArray[0], 1);
      }
      setSelectedWidgets([...selectedWidgets]);
    } else {
      dragErrorStatus = false;
    }
  }
  function handleOnDrag(e, type) {
    e.dataTransfer.setData("widgetType", JSON.stringify(type));
  }

  function handleOnDrop(e, index, subIndex) {
    let widgetType = JSON.parse(
      e.dataTransfer.getData("moveWidget") ||
        e.dataTransfer.getData("widgetType") ||
        null
    );
    if (widgetType !== undefined || widgetType !== null) {
      if (widgetType) {
        let jsonData = {};
        if (typeof widgetType === "string") {
          if (Widgets[widgetType] !== undefined) {
            jsonData.childComponent = [deepClone(Widgets[widgetType])];
          } else if (Layout[widgetType] !== undefined) {
            jsonData.childComponent = [deepClone(Layout[widgetType])];
          } else {
            jsonData.childComponent = [deepClone(Media[widgetType])];
          }
          jsonData.childComponent[0]["id"] = `${
            jsonData.childComponent[0].element
          }_${uniqueID(4)}`;
          jsonData.childComponent[0].width = jsonData.childComponent[0].width
            ? jsonData.childComponent[0].width
            : 6;
          if (
            index !== undefined &&
            subIndex !== undefined &&
            selectedWidgets[index]
          ) {
            selectedWidgets[index].childComponent[subIndex].childComponent.push(
              jsonData
            );
          } else {
            selectedWidgets.push(jsonData);
          }
        } else {
          if (widgetType.hasOwnProperty("json")) {
            selectedWidgets.push({ childComponent: [widgetType.json] });
          } else {
            selectedWidgets.push({ childComponent: [widgetType] });
          }
        }
        setSelectedWidgets([...selectedWidgets]);
      }
    }
    e.dataTransfer.setData("moveWidget", null);
    e.dataTransfer.setData("widgetType", null);
  }
  function onClickWidgetSection(widgetType) {
    let jsonData = {};
    if (Widgets[widgetType] !== undefined) {
      jsonData.childComponent = [deepClone(Widgets[widgetType])];
    } else if (Layout[widgetType] !== undefined) {
      jsonData.childComponent = [deepClone(Layout[widgetType])];
    } else {
      jsonData.childComponent = [deepClone(Media[widgetType])];
    }
    jsonData.childComponent[0]["id"] = `${
      jsonData.childComponent[0].element
    }_${uniqueID(4)}`;
    jsonData.childComponent[0].width = jsonData.childComponent[0].width
      ? jsonData.childComponent[0].width
      : 6;
    selectedWidgets.push(jsonData);
    setSelectedWidgets([...selectedWidgets]);
  }
  function onClickSubFormSection(index) {
    let jsonData = subForm[index];
    selectedWidgets.push({ childComponent: [jsonData] });
    setSelectedWidgets([...selectedWidgets]);
  }
  function sectionHandleOnDrop(e, index) {
    let widgetType = JSON.parse(
      e.dataTransfer.getData("moveWidget") ||
        e.dataTransfer.getData("widgetType") ||
        null
    );
    let width = 0;
    let usedWidth = 0;
    let totalWidth = 12;
    if (selectedWidgets[index] == undefined) {
      if (widgetType.path[0] < index) {
        index -= 1;
      } else {
        index += 1;
      }
    }
    selectedWidgets[index].childComponent.map((row) => {
      usedWidth += row.width;
    });
    let widthRemaining = totalWidth - usedWidth;
    if (widthRemaining > 6) {
      width = 6;
    } else {
      width = widthRemaining;
    }
    let widgetJson = {};
    if (typeof widgetType == "string") {
      if (Widgets[widgetType] !== undefined) {
        widgetJson = deepClone(Widgets[widgetType]);
      } else if (Layout[widgetType] !== undefined) {
        widgetJson = deepClone(Layout[widgetType]);
      } else {
        widgetJson = deepClone(Media[widgetType]);
      }

      widgetJson["id"] = `${widgetJson.element}_${uniqueID(4)}`;
    } else {
      widgetJson = widgetType.json;
    }

    if (
      widgetJson.hasOwnProperty("minRequiredWidth") &&
      widgetJson.minRequiredWidth > width
    ) {
      dragErrorStatus = true;
      alert(
        `Not allowed, minimum width required is ${widgetJson.minRequiredWidth}`
      );
    } else {
      selectedWidgets[index].childComponent.push(
        deepClone({ ...widgetJson, ...{ width: width } })
      );
      setSelectedWidgets([...selectedWidgets]);
    }
  }
  function removeWidget(index, refVal) {
    if (selectedWidgets[index].childComponent.length > 1) {
      selectedWidgets[index].childComponent.splice(refVal, 1);
    } else {
      selectedWidgets.splice(index, 1);
    }
    setSelectedWidgets([...selectedWidgets]);
  }

  function updateSelectedField(data) {
    if (selectedWidget.element === "Submit") {
      setFormSubmitSettings({ ...data });
    } else if (data.hasOwnProperty("title")) {
      setFormSettings({ ...data });
    } else {
      let dataPath = "";
      selectedIndex.map((value, index) => {
        if (index == 0) {
          dataPath += `[${value}]`;
        } else {
          dataPath += `.childComponent[${value}]`;
        }
      });
      setSelectedWidgets([..._.set(selectedWidgets, dataPath, data)]);
    }
    setSelectedWidget(deepClone(data));
  }

  async function saveFormStructure() {
    setSaveFormStatus(true);
    if (formSettings.title.label !== "") {
      let data = {
        structure: selectedWidgets,
        submitSettings: formSubmitSettings,
        formSettings: formSettings,
      };
      if (saveAsSubForm) {
        data["category"] = selectedCategoryId;
      }
      if (id) {
        await apiPutMethod(
          saveAsSubForm ? allApiUrl.SubForm : allApiUrl.Form,
          data,
          id
        )
          .then((response) => {
            setSaveFormStatus(false);
            toastMessage(
              "success",
              response.data?.message //`${saveAsSubForm ? "SubForm" : "Form"} Saved Successfully`
            );
            navigate("/");
          })
          .catch((error) => {
            console.log(error);
            toastMessage("error", error.data?.message);
          })
          .finally(() => {
            setSaveFormStatus(false);
          });
      } else {
        await apiPostMethod(
          saveAsSubForm ? allApiUrl.SubForm : allApiUrl.Form,
          data
        )
          .then((response) => {
            setSaveFormStatus(false);
            toastMessage(
              "success",
              response.data?.message //`${saveAsSubForm ? "SubForm" : "Form"} Saved SuccessFully`
            );
            navigate("/");
          })
          .catch((error) => {
            toastMessage("error", error.data?.message);
          })
          .finally(() => {
            setSaveFormStatus(false);
          });
      }
    } else {
      toastMessage(
        "error",
        `Please Set ${saveAsSubForm ? "SubForm" : "Form"} Title`
      );
    }
  }
  function UpdateSectionUpdate(data, index, sectionIndex) {
    selectedWidgets[index].childComponent[sectionIndex] = data;
    setSelectedWidgets([...selectedWidgets]);
  }
  useEffect(() => {
    if (document.getElementById("formBuilderView")) {
      let allInputs = document
        .getElementById("formBuilderView")
        .getElementsByTagName("input");
      if (allInputs.length > 0) {
        for (let i = 0; i < allInputs.length; i++) {
          allInputs[i].readonly = true;
          allInputs[i].disabled = true;
        }
      }
    }
  }, [selectedWidgets]);

  useEffect(() => {
    if (selectedWidgets && selectedWidget.element == "Content") {
      setShowIds(true);
    } else {
      setShowIds(false);
    }
  }, [selectedWidget]);

  function pasteFunction(index = undefined) {
    let jsonData = deepClone(copyWidgetJson);
    if (jsonData.element == "Copyform") {
      if (index !== undefined) {
        selectedWidgets[index].childComponent.push(...jsonData.jsonData);
      } else {
        selectedWidgets.push(...jsonData.jsonData);
      }
      setSelectedWidgets([...selectedWidgets]);
    } else {
      jsonData["id"] = `${jsonData.element}_${uniqueID(4)}`;
      jsonData.width = jsonData.width ? jsonData.width : 6;
      if (index !== undefined) {
        selectedWidgets[index].childComponent.push(jsonData);
      } else {
        selectedWidgets.push({ childComponent: [jsonData] });
      }
      setSelectedWidgets([...selectedWidgets]);
    }
  }
  function activeDropBoxHandleOnDrop(e, arrayPath, index, widthRemaining) {
    let widgetType = JSON.parse(
      e.dataTransfer.getData("moveWidget") ||
        e.dataTransfer.getData("widgetType") ||
        null
    );
    if (widgetType !== undefined || widgetType !== null) {
      let path = "";
      if (arrayPath.length > 0) {
        arrayPath.map((value, index) => {
          if (index == 0) {
            path += `[${value}]`;
          } else if (index > 0) {
            path += `.childComponent[${value}]`;
          }
        });
      }
      let Data = _.get(selectedWidgets, path, []);
      if (widgetType) {
        let jsonData = {};
        if (typeof widgetType === "string") {
          if (Widgets[widgetType] !== undefined) {
            jsonData = deepClone(Widgets[widgetType]);
          } else if (Layout[widgetType] !== undefined) {
            jsonData = deepClone(Layout[widgetType]);
          } else {
            jsonData = deepClone(Media[widgetType]);
          }
          jsonData["id"] = `${jsonData.element}_${uniqueID(4)}`;
          Data.childComponent.splice(index, 0, jsonData);
        } else {
          if (widgetType.hasOwnProperty("json")) {
            Data.childComponent.splice(index, 0, widgetType.json);
            removeWidget(...widgetType.path);
          } else {
            Data.childComponent.splice(index, 0, widgetType);
          }
        }
      }
      if (widthRemaining > 0) {
        Data.childComponent[index].width = Data.childComponent[index].width =
          widthRemaining;
      } else {
        Data.childComponent[index].width = Data.childComponent[index].width = 2;
        Data.childComponent[index + 1].width =
          Data.childComponent[index + 1].width - 2;
      }
      setSelectedWidgets([...selectedWidgets]);
    }
    e.dataTransfer.setData("moveWidget", null);
    e.dataTransfer.setData("widgetType", null);
    setActiveDropBox([]);
  }
  const getMapItems = (item, index) => {
    let usedWidth = 0;
    let totalWidth = 12;
    selectedWidgets[index].childComponent.map((row) => {
      usedWidth += row.width;
    });
    let widthRemaining = totalWidth - usedWidth;
    return (
      <div className="row">
        {item.childComponent.map((row, refVal) => {
          let Tag = widgetsHtml[row.element];
          return (
            <div
              className={`col-lg-${
                row.width ? row.width : 6
              } position-relative`}
              key={`childCompo3_${row.id}`}
              onMouseLeave={() => {
                setActiveWidgetId({ activeId: "", previousActiveId: [] });
              }}
              onMouseEnter={(e) => {
                setActiveWidgetId({ activeId: row.id, previousActiveId: [] });
              }}
              draggable
              onDragStart={(e) => {
                if (!["Section", "RepeatingSection"].includes(row.element))
                  handleMoveWidgetOnDrag(e, row, [index, refVal]);
              }}
              onDragEnd={(e) => {
                if (!["Section", "RepeatingSection"].includes(row.element))
                  setTimeout(() => {
                    removeWidgetAfterDrop([index, refVal]);
                  }, 300);
              }}
            >
              <button
                className="addColumnButton"
                onClick={() => {
                  setActiveDropBox([index, refVal]);
                }}
              >
                <i className="fas fa-plus-circle"></i>
              </button>
              <div className="row">
                {activeDropBox[0] == index && activeDropBox[1] == refVal && (
                  <div
                    className="col-lg-2"
                    onDrop={(e) =>
                      activeDropBoxHandleOnDrop(
                        e,
                        [index],
                        refVal,
                        widthRemaining
                      )
                    }
                    onDragOver={handleDragOver}
                  >
                    <div
                      className="widgedropBox"
                      onMouseEnter={(e) => {
                        if (e.target.childNodes[0].className == "icons")
                          e.target.childNodes[0].style.display = "inline-block";
                      }}
                      onMouseLeave={(e) => {
                        if (
                          e.target.childNodes.length == 2 &&
                          e.target.childNodes[0].className &&
                          e.target.childNodes[0].className == "icons"
                        )
                          e.target.childNodes[0].style.display = "none";
                      }}
                    >
                      {copyWidgetJson.hasOwnProperty("element") && (
                        <span className="icons">
                          <a
                            role="button"
                            type="button"
                            onClick={() => {
                              pasteFunction(index);
                            }}
                          >
                            <i className="fas fa-paste"></i> <br />
                            Paste
                          </a>
                        </span>
                      )}
                      <span>Drop Filed Here</span>
                    </div>
                  </div>
                )}
                <div
                  className={
                    activeDropBox[0] == index && activeDropBox[1] == refVal
                      ? "col-lg-10"
                      : "col"
                  }
                >
                  <div className="form-group">
                    <span
                      className="icons"
                      style={
                        activeWidgetId.activeId == row.id
                          ? {
                              display: "inline-flex",
                              zIndex: 1,
                            }
                          : {}
                      }
                    >
                      <a
                        role="button"
                        type="button"
                        onClick={() => {
                          setSelectedIndex([index, refVal]);
                          setSelectedWidget(deepClone(row));
                          setIsOpen(true);
                        }}
                      >
                        <i className="fa-regular fa-pen-to-square"></i> <br />{" "}
                        Edit
                      </a>

                      {!row.hasOwnProperty("widthEditable") && (
                        <>
                          <a
                            role="button"
                            type="button"
                            onClick={() => {
                              row.width > 2
                                ? (row.width -= 1)
                                : (row.width = row.width ? row.width : 4);
                              selectedWidgets[index].childComponent[refVal] =
                                row;
                              setSelectedWidgets([...selectedWidgets]);
                            }}
                          >
                            <i className="fa-solid fa-arrow-left"></i> <br />{" "}
                            Shrink
                          </a>
                          <a
                            role="button"
                            disabled={row.width == 12}
                            type="button"
                            onClick={() => {
                              if (widthRemaining > 0) {
                                row.width < 12
                                  ? (row.width += 1)
                                  : (row.width = row.width ? row.width : 8);
                                selectedWidgets[index].childComponent[refVal] =
                                  row;
                                setSelectedWidgets([...selectedWidgets]);
                              }
                            }}
                          >
                            <i className="fa-solid fa-arrow-right"></i> <br />{" "}
                            Grow
                          </a>
                          <a
                            role="button"
                            type="button"
                            onClick={() => [setCopyWidgetJson({ ...row })]}
                          >
                            <i className="fa-solid fa-copy"></i>
                            <br /> Copy
                          </a>
                        </>
                      )}
                      <a
                        role="button"
                        type="button"
                        onClick={() => {
                          removeWidget(index, refVal);
                        }}
                      >
                        <i className="fa-solid fa-trash"></i> <br /> Delete
                      </a>
                      {/* <a role="button" type="button"><i className="fa-solid fa-ellipsis-vertical"></i> <br /> Move</a> */}
                    </span>
                    {
                      <span className={`rowId ${showIds ? "show" : ""}`}>
                        {row.id}
                      </span>
                    }
                    <Tag
                      index={index}
                      data={row}
                      onChangeFields={onChangeFields}
                      handleOnDrop={handleOnDrop}
                      UpdateSectionUpdate={UpdateSectionUpdate}
                      sectionIndex={refVal}
                      setSelectedWidget={setSelectedWidget}
                      setIsOpen={setIsOpen}
                      setSelectedIndex={(arrayPath) => {
                        arrayPath.unshift(refVal);
                        arrayPath.unshift(index);
                        setSelectedIndex(arrayPath);
                      }}
                      setActiveWidgetId={setActiveWidgetId}
                      activeWidgetId={activeWidgetId}
                      copyWidgetJson={copyWidgetJson}
                      setCopyWidgetJson={setCopyWidgetJson}
                      showIds={showIds}
                      activeDropBoxHandleOnDrop={(
                        e,
                        arrayPath,
                        updateIndex,
                        widthRemaining
                      ) => {
                        arrayPath.unshift(refVal);
                        arrayPath.unshift(index);
                        activeDropBoxHandleOnDrop(
                          e,
                          arrayPath,
                          updateIndex,
                          widthRemaining
                        );
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          );
        })}
        {widthRemaining > 0 && (
          <div
            className={`col-lg-${widthRemaining}`}
            onDrop={(e) => sectionHandleOnDrop(e, index)}
            onDragOver={handleDragOver}
          >
            <div
              className="widgedropBox"
              onMouseEnter={(e) => {
                if (e.target.childNodes[0].className == "icons")
                  e.target.childNodes[0].style.display = "inline-block";
              }}
              onMouseLeave={(e) => {
                if (
                  e.target.childNodes.length == 2 &&
                  e.target.childNodes[0].className &&
                  e.target.childNodes[0].className == "icons"
                )
                  e.target.childNodes[0].style.display = "none";
              }}
            >
              {copyWidgetJson.hasOwnProperty("element") && (
                <span className="icons">
                  <a
                    role="button"
                    type="button"
                    onClick={() => {
                      pasteFunction(index);
                    }}
                  >
                    <i className="fas fa-paste"></i> <br />
                    Paste
                  </a>
                </span>
              )}
              <span>Drop Fields Here</span>
            </div>
          </div>
        )}
      </div>
    );
  };
  function SaveAsModal(props) {
    const customStyles = {
      content: {
        top: "50%",
        left: "50%",
        right: "100%",
        bottom: "auto",
        marginRight: "-30%",
        transform: "translate(-50%, -50%)",
      },
    };

    return (
      <div id="SaveAsModal">
        <Modal
          isOpen={openSaveAsModal}
          onRequestClose={() => setOpenSaveAsModal(false)}
          style={customStyles}
          contentLabel="Save As Modal"
          appElement={document.getElementById("SaveAsModal")}
        >
          <div className="modalHeader">
            <h4>Save As</h4>
            <button
              className="btn btn-closeModal"
              onClick={() => {
                setOpenSaveAsModal(false);
                setSaveFormStatus(false);
              }}
            >
              <img src={crossIcon} />
            </button>
          </div>
          <div className="saveModalBody">
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                checked={saveAsSubForm}
                disabled={form == "subform"}
                onChange={(e) => setSaveAsSubForm(e.target.checked)}
              />
              <label className="form-check-label">Save As JSA Code</label>
            </div>
            <div className="form-group">
              <label className="control-label">Select Categories</label>
              <select
                className="form-control"
                disabled={!saveAsSubForm}
                value={selectedCategoryId}
                onChange={(e) => {
                  setSelectedCategoryId(e.target.value);
                }}
              >
                {categoriesList.map((category, index) => {
                  return (
                    <option
                      key={`choice1__${index}`}
                      data={selectedCategoryId}
                      selected={selectedCategoryId === category._id}
                      value={category._id}
                    >
                      {category.name}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
          <div className="btnSection text-end p-3">
            <button
              className="btn btn-save"
              disabled={saveFormStatus}
              onClick={() => {
                saveFormStructure(selectedCategoryId);
              }}
            >
              {!saveFormStatus ? "Save" : <Loader />}
            </button>
          </div>
        </Modal>
      </div>
    );
  }

  function SubmitForm(hideIcons) {
    const [showIcons, setShowIcons] = useState(false);
    return (
      <div
        className="row"
        onMouseEnter={() => setShowIcons(true)}
        onMouseLeave={() => setShowIcons(false)}
      >
        <div className="col-lg-12">
          <div className="form-group">
            <span
              hidden={hideIcons}
              className={`icons${showIcons ? " show" : ""}`}
            >
              <a
                role="button"
                type="button"
                onClick={() => {
                  setSelectedWidget(deepClone(formSubmitSettings));
                  setIsOpen(true);
                }}
              >
                <i className="fa-regular fa-pen-to-square"></i> <br /> Edit
              </a>
            </span>
            <div className="btnGroup">
              {formSubmitSettings.buttons.map((button, index) => {
                return (
                  <button
                    key={`btn__${index}`}
                    className="btn btn-secondary me-2"
                    onClick={() => {}}
                  >
                    {button.buttonText}
                  </button>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    );
  }

  function ToggleGridView(e) {
    let showClass = "open";
    if (e.target.parentNode.nextSibling.classList.contains(showClass)) {
      e.target.parentNode.nextSibling.classList.remove(showClass);
    } else {
      e.target.parentNode.nextSibling.classList.add(showClass);
    }
  }

  const [cloneButton, setCloneButton] = useState(false);
  function cloneToggle() {
    if (window.location.pathname.includes("form-builder/form")) {
      setCloneButton(true);
    }
  }
  useEffect(() => {
    cloneToggle();
  }, []);

  async function cloneForm() {
    if (formSettings.title.label !== "") {
      let formSetting = JSON.parse(JSON.stringify(formSettings));
      formSettings.title.label = "Cloned " + formSetting.title.label;
      let data = {
        structure: selectedWidgets,
        submitSettings: formSubmitSettings,
        formSettings: formSettings,
      };
      await apiPostMethod(
        saveAsSubForm ? allApiUrl.SubForm : allApiUrl.Form,
        data
      ).then((response) => {
        setSaveFormStatus(false);
        toastMessage(
          "success",
          `${saveAsSubForm ? "SubForm" : "Form"} Saved SuccessFully`
        );
        navigate("/");
      });
    }
  }
  return (
    <>
      <div className="header">
        <button
          className="menuButton"
          onClick={() => {
            setShowSidebar(!showSidebar);
          }}
        >
          <img src={menuIcon} />
        </button>
        <a className="brandName" href="/">
          <img src={Logo}></img>
        </a>
        <div className="d-flex">
          {cloneButton ? (
            <button className="btn btn-save" onClick={() => cloneForm()}>
              Clone
            </button>
          ) : (
            ""
          )}

          {cloneButton ? (
            <button
              className="btn btn-save"
              onClick={() => {
                setCopyWidgetJson(
                  deepClone({ element: "Copyform", jsonData: selectedWidgets })
                );
                toastMessage("success", "Form Copied");
              }}
            >
              Copy
            </button>
          ) : (
            ""
          )}
          <button
            className="btn btn-save"
            onClick={() => {
              setOpenPreviewModal(true);
              localStorage.setItem(
                "previewWidgetsData",
                JSON.stringify(selectedWidgets)
              );
              localStorage.setItem(
                "formSettings",
                JSON.stringify(formSettings)
              );
              localStorage.setItem(
                "formSubmitSettings",
                JSON.stringify(formSubmitSettings)
              );
            }}
          >
            Preview
          </button>
          <button
            className="btn btn-save"
            onClick={() => setOpenSaveAsModal(true)}
          >
            Save
          </button>
        </div>
      </div>
      {openSaveAsModal && <SaveAsModal />}
      {openPreviewModal && (
        <PreviewModal
          openPreviewModal={openPreviewModal}
          setOpenPreviewModal={setOpenPreviewModal}
          FormHeader={FormHeader}
          SubmitForm={SubmitForm}
          setSelectedWidget={setSelectedWidget}
          selectedWidgets={selectedWidgets}
          selectedWidget={selectedWidget}
          setFormSettings={setFormSettings}
          formSettings={formSettings}
          setIsOpen={setIsOpen}
        />
      )}
      <div className="ReactFormBuilder" id="ReactFormBuilder">
        <div className={`react-form-widgets ${showSidebar ? " show" : ""}`}>
          <div className={`sidebar${moveWidgets ? " top" : ""}`}>
            {!modalIsOpen && (
              <>
                <div className="sidebarInner toggleSection">
                  <h3>
                    Add Fields<span onClick={(e) => ToggleGridView(e)}></span>
                  </h3>
                  <div className="WidgetOuter open">
                    {Object.keys(Widgets).map((row, index) => {
                      if (Widgets[row].listingHidden) {
                        return;
                      }
                      return (
                        <div
                          key={`choice3__${index}`}
                          className="widget"
                          onClick={(e) => onClickWidgetSection(row)}
                          draggable
                          onDragStart={(e) => {
                            handleOnDrag(e, row);
                          }}
                        >
                          {Widgets[row].name}
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div className="sidebarInner toggleSection">
                  <h3>
                    Layout <span onClick={(e) => ToggleGridView(e)}></span>
                  </h3>
                  <div className="WidgetOuter open">
                    {Object.keys(Layout).map((row, index) => {
                      return (
                        <div
                          key={`choice4__${index}`}
                          className="widget"
                          onClick={(e) => onClickWidgetSection(row)}
                          draggable
                          onDragStart={(e) => {
                            handleOnDrag(e, row);
                          }}
                        >
                          {Layout[row].name}
                        </div>
                      );
                    })}
                  </div>
                </div>

                <div className="sidebarInner toggleSection">
                  <h3>
                    Media<span onClick={(e) => ToggleGridView(e)}></span>
                  </h3>
                  <div className="WidgetOuter open">
                    {Object.keys(Media).map((row, index) => {
                      return (
                        <div
                          key={`choice5__${index}`}
                          className="widget"
                          onClick={(e) => onClickWidgetSection(row)}
                          draggable
                          onDragStart={(e) => {
                            handleOnDrag(e, row);
                          }}
                        >
                          {Media[row].name}
                        </div>
                      );
                    })}
                  </div>
                </div>

                <button
                  className={`btn btn-subform${showSubForm ? " active" : ""} `}
                  onClick={(e) => {
                    setShowSubForm(!showSubForm);
                  }}
                >
                  Add JSA Code{" "}
                </button>
                {showSubForm && (
                  <>
                    <div className="dropdown subformDropdown">
                      <button
                        className="btn btn-secondary dropdown-toggle"
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        {subFormFilter.category._id
                          ? subFormFilter.category.name
                          : "Select"}
                      </button>
                      <ul className="dropdown-menu">
                        {categoriesList.map((category, index) => {
                          return (
                            <li key={`li2__${index}`}>
                              <a
                                className="dropdown-item"
                                onClick={() => {
                                  subFormFilter.category = category;
                                  setSubFormFilter({ ...subFormFilter });
                                }}
                              >
                                {category.name}
                              </a>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                    <div className="subForms">
                      <input
                        className="searchField"
                        type="text"
                        placeholder="Search SubForm"
                        value={subFormFilter.string}
                        onChange={(e) => {
                          setSubFormFilter((prevData) => {
                            prevData.string = e.target.value;
                            return { ...prevData };
                          });
                        }}
                      />
                      <button
                        className="clearBtn"
                        onClick={() =>
                          setSubFormFilter({
                            category: { _id: undefined, name: "" },
                            string: "",
                          })
                        }
                      >
                        Clear
                      </button>
                      <ul className="dataDropdown">
                        {subForm.map((row, index) => {
                          if (
                            row.label
                              .toLowerCase()
                              .includes(subFormFilter.string.toLowerCase())
                          ) {
                            if (
                              subFormFilter.category._id !== undefined &&
                              row.category != subFormFilter.category._id
                            ) {
                              return;
                            }
                            return (
                              <li
                                key={`choice7__${index}`}
                                onClick={() => {
                                  onClickSubFormSection(index);
                                }}
                                value={index}
                                draggable
                                onDragStart={(e) => {
                                  handleOnDrag(e, row);
                                }}
                              >
                                {row.label}
                              </li>
                            );
                          } else {
                            return;
                          }
                        })}
                      </ul>
                    </div>
                  </>
                )}
              </>
            )}
            <EditFieldsModal
              modalStatus={modalIsOpen}
              setModalStatus={setIsOpen}
              data={selectedWidget}
              selectedWidgets={selectedWidgets}
              updateSelectedField={updateSelectedField}
              setSelectedWidget={setSelectedWidget}
            />
          </div>
        </div>
        {fetchingData ? (
          <div className="loaderOuter">
            <div className="lds-ring">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        ) : (
          <div className="widget-dropsecton" id="formBuilderView">
            <div className="outerFromBuilder">
              <FormHeader
                setSelectedWidget={setSelectedWidget}
                selectedWidgets={selectedWidgets}
                selectedWidget={selectedWidget}
                setFormSettings={setFormSettings}
                formSettings={formSettings}
                setIsOpen={setIsOpen}
              />
              {selectedWidgets.map((row, index) => {
                return getMapItems(row, index);
              })}
              <div
                className="row"
                onDrop={handleOnDrop}
                onDragOver={handleDragOver}
                onClick={() => {
                  setIsOpen(false);
                  setSelectedWidget({});
                }}
              >
                <div className="col-lg-6">
                  <div
                    className="widgedropBox"
                    style={{ minHeight: "150px" }}
                    onMouseEnter={(e) => {
                      if (e.target.childNodes[0].className == "icons")
                        e.target.childNodes[0].style.display = "inline-block";
                    }}
                    onMouseLeave={(e) => {
                      if (e.target.childNodes[0].className == "icons")
                        e.target.childNodes[0].style.display = "none";
                    }}
                  >
                    {copyWidgetJson.hasOwnProperty("element") && (
                      <span className="icons">
                        <a
                          role="button"
                          type="button"
                          onClick={() => {
                            pasteFunction();
                          }}
                        >
                          <i className="fas fa-paste"></i> <br />
                          Paste
                        </a>
                      </span>
                    )}
                    <span>Drop Fields Here</span>
                  </div>
                </div>
              </div>
              <SubmitForm />
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default ReactFormBuilder;
