import { useEffect, useState } from "react";
import "react-querybuilder/dist/query-builder.scss";
import "../QueryBuilder/style.css";
import "bootstrap-icons/font/bootstrap-icons.scss";
import "bootstrap/scss/bootstrap.scss";
import TextAreaWithSuggestions from "../shared/TextareaWithSuggestions";
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import {createSuggestionList} from "../../shared/functions/array-conversion";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));


export const validator = (r) => !!r.value;

const initialQuery = { combinator: "and", rules: [] };

function CustomDialog({
        ModalOpen,
        setModalOpen,
        updateRule,
        rulesQuery,
        selectedWidgets,
        editForm,
        heading,
        editElementProp
  }) {
  const [modalIsOpen, setIsOpen] = useState(false);
  const [query, setQuery] = useState(initialQuery);
  const [value, setValue] = useState("");
  const [startIndex, setStartIndex] = useState();

  useEffect(() => {
    setIsOpen(ModalOpen);
  }, [ModalOpen]);
  function closeModal() {
    setIsOpen(false);
    setModalOpen(false);
  }

  function saveRules() {
    if (query && query?.hasOwnProperty("rules")) {
      updateRule(query, "rules");
      setIsOpen(false);
      setModalOpen(false);
    }
  }
  const handleChange = (e) => {
    setValue(e.target.value);
    setStartIndex(e.target.selectionStart);
  };


  return (
    <BootstrapDialog
      onClose={closeModal}
      aria-labelledby="customized-dialog-title"
      open={modalIsOpen}
    >
      <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
        {heading}
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={closeModal}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent dividers>
        <div className="custom-dialog-container">
          {editForm?.hasOwnProperty("calculation") ?
            <form>
              <div className="row">
                <div className="col-sm-12 col-lg-6">
                  <div className="form-group h-100">
                    <TextAreaWithSuggestions selectedWidgets={selectedWidgets} inputValProp={editForm?.calculation}
                                             editElementProp={editElementProp} />
                  </div>
                </div>
                <div className="col-sm-12 col-lg-6">
                  <div className="c-expression-help">
                    <div className="c-expression-help-number">
                      <h2>Common Number Calculations</h2>
                      <div className="c-label">Addition</div>
                      <div className="c-helptext">Number1 + Number2</div>
                      <div className="c-label">Subtraction</div>
                      <div className="c-helptext">Number1 - Number2</div>
                      <div className="c-label">Multiplication</div>
                      <div className="c-helptext">Number1 * Number2</div>
                      <div className="c-label">Division</div>
                      <div className="c-helptext">Number1 / Number2</div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
            :
            <form>
              <div className="form-group">
                <label htmlFor="exampleFormControlTextarea1"></label>
                <textarea
                  className="form-control"
                  id="exampleFormControlTextarea1"
                  rows="3"
                  value={value}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                ></textarea>
              </div>
            </form>
          }
        </div>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={saveRules}>
          Save
        </Button>
      </DialogActions>
    </BootstrapDialog>
  );
}

export default CustomDialog;