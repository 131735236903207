import React, { useEffect, useState } from 'react';
import * as markerjs2 from 'markerjs2';
import Modal from 'react-modal';
import crossIcon from '../../assets/img/iconCross.svg';
import './style.css'
import AWS from 'aws-sdk';
import Loader from '../Loader';
import { Buffer } from "buffer";
import toastMessage from "../ToastMessage"


const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: '100%',
        bottom: 'auto',
        width: '30%',
        transform: 'translate(-50%, -50%)',
    },
};
const URL_EXPIRATION_SECONDS = 30
AWS.config.update({
    accessKeyId: process.env.REACT_APP_AWS_S3_BUCKET_SECRET_ACCESS_KEY,
    secretAccessKey: process.env.REACT_APP_AWS_S3_BUCKET_ACCESS_KEY_ID,
    region: process.env.REACT_APP_AWS_S3_BUCKET_REGION,
    signatureVersion: 'v4',
});

function ImageEditor({ file, setSelectedFile, modalIsOpen, setIsOpen, saveData }) {
    let imgRef = React.createRef();
    const [uploading, setUploading] = useState(false);
    const [imageBlob, setImageBlob] = useState("");
    const s3 = new AWS.S3();
    function showMarkerArea() {
        if (imgRef.current !== null) {
            const markerArea = new markerjs2.MarkerArea(imgRef.current);
            markerArea.settings.displayMode = "popup";
            markerArea.addEventListener('render', event => {
                if (imgRef.current) {
                    imgRef.current.src = event.dataUrl;
                }
            });
            markerArea.show();
        }
    }
    function closeModal() {
        setIsOpen(false);
        setSelectedFile({});
        setUploading(false)
    }

    async function uploadToS3() {
        try {
            if (imgRef.current.src.length < 10) {
                return;
            }
            setUploading(true)
            var buf = Buffer.from(imgRef.current.src.replace(/^data:image\/\w+;base64,/, ""), 'base64')
            const params = {
                Bucket: process.env.REACT_APP_AWS_S3_BUCKET_NAME,
                Key: `${file.name}`,
                Body: buf,
                Expires: URL_EXPIRATION_SECONDS,
                ContentEncoding: 'base64',
                ContentType: 'image/jpeg'
            };
            const response = await s3.upload(params).promise();
            if (response.Key) {
                setUploading(false)
                saveData(response.Key)
                toastMessage("success", `Uploaded SuccessFully`)
                closeModal();
            } else {
                toastMessage("error", response)
            }
        } catch (err) {
            toastMessage("error", err.message !== undefined ? err.message : err)
        }
    }
    useEffect(() => {
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            setImageBlob(reader.result);
        };
        reader.onerror = function (error) {
            toastMessage("error", error.message !== undefined ? error.message : error)
        };
    }, [])

    return (
        <div className=''>
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Example Modal"
            >
                <div className="modalHeader previewModalHeader">
                    <h4>Preview</h4>
                    <button className="btn btn-closeModal" onClick={() => { closeModal() }}>
                        <img src={crossIcon} />
                    </button>
                </div>
                <div className="previewImg">
                    <img
                        ref={imgRef}
                        src={imageBlob}
                        alt="sample"
                    />
                </div>
                <div className="btnSection text-end p-3">
                    <button className="btn btn-save me-2" onClick={() => showMarkerArea()}>Edit</button>
                    <button className="btn btn-save" onClick={() => uploadToS3()}>{uploading ? <Loader /> : "Upload"}</button>
                </div>
            </Modal >
        </div >

    );
}

export default ImageEditor;